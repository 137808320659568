import React from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import LecturesView from 'app/components/SpaceTabs/LecturesView';
import MemberListingWrapper from 'app/components/BoardView/MemberListingWrapper';
import NavTabs from './index';
import ContentListing from './ContentListing';
import SpaceDiscussionsView from 'app/components/SpaceTabs/DiscussionsView';
import SpaceInformationView from 'app/components/SpaceTabs/InformationView';
import CareInvoicesListing from 'app/components/CareInvoices/CareInvoicesListing';

import { isClinicSpace, isCompanySpace } from 'app/utils/spaceHelper';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';
import PatientAndResponseParties from '../Widgets/PatientAndResponseParties';
import ClinicTeamWithUsers from 'app/components/Widgets/ClinicTeamWithUsers';
import RelatedTaskWidget from 'app/components/Task/RelatedTaskWidget';
import RecentCareSummaryDiscussions from 'app/components/CommentSection/RecentCareSummaryDiscussions';
import PopularHashTags from 'app/components/Widgets/PopularHashTags';
import TreatmentProgress from 'app/components/TreatmentProgress';
import ScheduledPosts from 'app/components/SpaceTabs/ScheduledPosts';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import StatusWidget from 'app/components/Widgets/StatusWidget';

const SPACE_NAVTAB_MAPPER = {
  activity: 'Activity',
  files: 'Files',
  patient_details: 'Patient Details',
  treatment_progress: 'Treatment Progress',
  related_tasks: 'Related Tasks',
  care_summary: 'Care Summary',
};

const ACTIVITY_TAB_TITLE_MAPPER = {
  all: 'ALL ACTIVITY',
  patient: 'PATIENT ACTIVITY',
  internal: 'INTERNAL ACTIVITY',
};

class SpaceNavTabContent extends NavTabs {
  isInformationFullWidth() {
    return (
      isWorkspaceView() &&
      this.props.isGuestUser &&
      (isClinicSpace(this.getEntity()) || isCompanySpace(this.getEntity()))
    );
  }

  renderSpaceMembers() {
    let space = this.getEntity();
    return (
      <MemberListingWrapper
        key={space.members_listing_layout}
        customData={{ space }}
        scrollIfNeeded={this.props.scrollIfNeeded}
        isCompanySpace={isCompanySpace(space)}
        tabDetails={this.selectedTab()}
      />
    );
  }

  renderTabSectionsListing() {
    let selectedTab = this.selectedTab();
    return (
      <ContentListing
        data={{ space: this.getEntity() }}
        filter={this.selectedFilter()}
        tabDetails={selectedTab}
        scrollIfNeeded={this.props.scrollIfNeeded}
        contentNotAccessible={!this.isContentAccessible(selectedTab)}
        searchRequired={this.props.searchRequired}
        searchText={this.props.searchText}
        cardViewType={this.props.cardViewType}
        filterValues={this.props.filterValues}
        sortQuery={this.props.sortQuery}
      />
    );
  }

  renderListingTypeTabContent() {
    let selectedFilter = this.selectedFilter();
    switch (selectedFilter) {
      case 'members':
      case 'team':
        return this.renderSpaceMembers();
      case 'lectures':
        return (
          <LecturesView
            data={{ space: this.getEntity() }}
            filter={selectedFilter}
            tabDetails={this.selectedTab()}
          />
        );
      default:
        return this.renderTabSectionsListing();
    }
  }

  renderContent() {
    let space = this.getEntity(),
      tab = this.selectedTab();

    switch (tab.content_type) {
      case 'discussion':
        return (
          <SpaceDiscussionsView
            data={{ space }}
            scrollIfNeeded={this.scrollIfNeeded}
          />
        );
      case 'listing':
        return this.renderListingTypeTabContent();
      case 'patient_details':
        return (
          <PatientAndResponseParties widgetSourceObject={this.props.space} />
        );
      case 'clinic_details':
        return (
          <ClinicTeamWithUsers widgetSourceObject={space?.owner?.clinic} />
        );
      case 'tasks':
        return <RelatedTaskWidget widgetSourceObject={this.props.space} />;
      case 'care_summary':
        return (
          <RecentCareSummaryDiscussions widgetSourceObject={this.props.space} />
        );
      case 'popular_hashtag':
        return <PopularHashTags />;
      case 'TreatmentProgress':
        return (
          <TreatmentProgress
            widgetSourceObject={this.props.activeResource.resource}
          />
        );
      case 'invoices':
        return <CareInvoicesListing spaceId={this.props.space.id} />;
      case 'scheduled_posts':
        return <ScheduledPosts widgetSourceObject={this.props.space} />;
      case 'patient_status':
        return <StatusWidget widgetSourceObject={this.props.space} />;
      default:
        return null;
    }
  }

  render() {
    let space = this.getEntity(),
      selectedTab = this.selectedTab();

    let ownerName, filterTab, filter;
    if (space?.owner?.patient?.name)
      ownerName = `${space?.owner?.patient?.name}'s Space`;
    else if (space?.display_name) ownerName = space?.display_name;
    filterTab = SPACE_NAVTAB_MAPPER[selectedTab.path];

    let feedType = this.props.match.params.feedType;
    if (feedType) filterTab = ACTIVITY_TAB_TITLE_MAPPER[feedType];

    filter = `${ownerName} &ensp;&ndash;&ensp; ${filterTab}`;

    if (selectedTab.content_type === 'information') {
      return (
        <SpaceInformationView
          fullWidth={this.isInformationFullWidth()}
          tabDetails={selectedTab}
          data={{ space }}
          scrollIfNeeded={this.props.scrollIfNeeded}
          key={selectedTab.id}
        />
      );
    } else {
      return (
        <div className='xl-container container-fluid px-hd-40 px-sm-32 px-m-12'>
          <SetPageTitle
            source={'patient_carespace_tab'}
            interpolations={filter}
          />
          {this.renderContent()}
        </div>
      );
    }
  }
}

SpaceNavTabContent = withRouter(SpaceNavTabContent);
SpaceNavTabContent = connect(
  ({ activeResource, currentUser, device }) => ({
    activeResource,
    currentUser,
    device,
  }),
  {},
)(SpaceNavTabContent);
SpaceNavTabContent.defaultProps = {
  scrollIfNeeded: () => {},
  searchRequired: true,
};
export default SpaceNavTabContent;
