import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

export const COMPONENT_MAPPER = {
  AccountSettings: {
    default: {
      Component: Navigate,
      componentProps: { to: '/not_found', path: '/account_settings/privacy' },
    },
  },
};

export const PHI_FEATURE_IDENTIFER = 'hide_senstive_patient_data';

export const CASE_DETAIL_FEAUTURE_IDENTIFIER = 'case_detail_view';

export const CASE_ANALYTICS_IDENTIFIER = 'case_analytics_identifier';
export const CONTRACT_ADDITION_IDENTIFIER = 'contract_addition';
