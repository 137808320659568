import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { setSource } from 'app/actions/source';

import { Navigate, Route, Routes } from 'react-router-dom';
import { isEmpty } from 'app/utils/osLodash';
import AccountSettingView from 'app/components/AccountSettingView';
import AuthenticateSharedSpace from 'app/components/AuthenticateSharedSpace';
import BoardView from 'app/components/BoardView';
import CaseView from 'app/components/CaseView';
import CCPAPolicyView from 'app/components/CCPAPolicyView';
import CommentView from 'app/components/CommentView';
import Designs from 'app/components/Designs';
import DownloadAsset from 'app/components/DownloadAsset';
import DynamicFolderTemplateView from 'app/components/DynamicFolderTemplateView';
import EditUserProfile from 'app/components/EditUserProfile';
import ExploreFeed from 'app/components/ExploreFeed';
import NonSubscribedView from 'app/components/NonSubscribedView';
import RedirectToDefault from 'app/components/RedirectToDefault';
import ResourceUnavailableView from 'app/components/ResourceUnavailableView';
import SpaceContentListing from 'app/components/WorkspaceView/SpaceTabs/ContentListing';
import TerminateSession from 'app/components/TerminateSession';
import ThreeColumnLayout from 'app/components/Layouts/ThreeColumnLayout';
import UserView from 'app/components/UserView';
import WorkspaceDefaultRoutes from 'app/routes/WorkspaceView/DefaultRoutes';
import WorkspaceSettingView from 'app/components/WorkspaceView/SettingView';
import TeamSpace from 'app/components/TeamSpace';
import TeamSpaceCommunicationsView from 'app/components/TeamSpaceCommunicationsView';
import ProfileSetAndUpdateForm from 'app/components/SwitchProfile/ProfileSetAndUpdateForm';
import SwitchProfile from 'app/components/SwitchProfile/SwitchProfile';
import Logout from 'app/components/Logout/Logout';
import ProfileResetPassword from 'app/components/QuickSwitcher/ProfileResetPassword';
import BaseTaskManger from 'app/components/Task/BaseTaskManager';
import GroupMessenger from 'app/components/GroupMessenger';
import PartnerSpace from 'app/components/Partners/PartnerSpace';
import NoPartnerSpace from 'app/components/Partners/NoPartnerSpace';
import WorkspaceLayout from 'app/components/Layouts/WorkspaceLayout';
import {
  isUnifiedEnabled,
  withWorkspaceIdentifier,
} from 'app/utils/Workspace/generalHelper';
import { MY_FOLDER_FILES, MY_FOLDER_LINKS } from 'app/routes/constants/folders';
import TasksInbox from 'app/components/Inbox/TasksInbox';
import GroupsInbox from 'app/components/Inbox/GroupsInbox';
import ConversationsInbox from 'app/components/Inbox/ConversationsInbox';
import PartnersInbox from 'app/components/Inbox/PartnersInbox';
import TeamPartnersInbox from 'app/components/Inbox/TeamPartnersInbox';
import PatientsInbox from 'app/components/Inbox/PatientsInbox';
import TeamPatientsInbox from 'app/components/Inbox/TeamPatientsInbox';
import GlobalSearchView from 'app/components/GlobalSearchView/GlobalSearchView';

import EasyRXSetup from 'app/components/EasyRX/Setup';
import EasyRXPrescriptionList from 'app/components/EasyRX/PrescriptionsSection';

const WorkSpaceViewRoutes = (props) => {
  const directWorkSpaceLogin = useSelector(
    (state) => state.directWorkSpaceLogin,
  );
  const { setSource } = props;
  useEffect(() => {
    setSource('workspace');
  }, [setSource]);

  const workspace = props.workspace.data;
  if (!isEmpty(workspace)) {
    const rootPath = `/${workspace.identifier}`;
    const redirectPath = isUnifiedEnabled()
      ? withWorkspaceIdentifier('/inbox/my/tasks')
      : `/${workspace.identifier}`;
    return (
      <Routes>
        <Route path={rootPath} element={<WorkspaceLayout />}>
          <Route path='search' element={<GlobalSearchView />} />
          <Route element={<ThreeColumnLayout />}>
            <Route path='easy-rx/setup' exact element={<EasyRXSetup />} />
            <Route
              path='easy-rx/prescriptions/:filter'
              exact
              element={<EasyRXPrescriptionList />}
            />
            <Route path='' exact={true} element={<ExploreFeed />} />
            <Route path='explore' exact={true} element={<ExploreFeed />} />
            <Route
              path='explore/:feedType/:filterBy'
              element={<ExploreFeed />}
            />
            <Route path='explore/:feedType' element={<ExploreFeed />} />
            <Route
              path='patient/activity'
              exact={true}
              element={<ExploreFeed />}
            />
            <Route
              path='patient/activity/:feedType/:filterBy'
              element={<ExploreFeed />}
            />
            <Route
              path='patient/activity/:feedType'
              element={<ExploreFeed />}
            />
            <Route
              path='carespaces'
              element={
                <SpaceContentListing
                  type='care_feed'
                  tabDetails={{ kind: 'carespaces' }}
                  containerClass=''
                />
              }
            />
            <Route
              path='patient/directory/all'
              element={
                <SpaceContentListing
                  type='care_feed'
                  tabDetails={{ kind: 'carespaces' }}
                  containerClass=''
                  filter={'all'}
                />
              }
            />
            <Route
              path='patient/directory/no_response'
              element={
                <SpaceContentListing
                  type='care_feed'
                  tabDetails={{ kind: 'carespaces' }}
                  containerClass=''
                  filter={'no_response'}
                />
              }
            />
            <Route
              path='patient/directory/scheduled'
              element={
                <SpaceContentListing
                  type='care_feed'
                  tabDetails={{ kind: 'carespaces' }}
                  containerClass=''
                  filter={'scheduled'}
                />
              }
            />
            <Route
              path='patient/directory/not_accepted'
              element={
                <SpaceContentListing
                  type='care_feed'
                  tabDetails={{ kind: 'carespaces' }}
                  containerClass=''
                  filter={'not_accepted'}
                />
              }
            />
            <Route
              path='patient/directory/waiting'
              element={
                <SpaceContentListing
                  type='care_feed'
                  tabDetails={{ kind: 'carespaces' }}
                  containerClass=''
                  filter={'waiting'}
                />
              }
            />
            <Route
              path='patient/space/:id/:filter/:additionalFilter'
              element={<BoardView />}
            />
            <Route path='patient/space/:id/:filter' element={<BoardView />} />
            <Route path='patient/space/:id' element={<BoardView />} />
            {/* <Route
              path='spaces/:id/activity_logs'
              element={<CareSpaceActivityLogs />}
            /> */}
            {/* <Route
              path='spaces/:id/:filter/:additionalFilter'
              element={<BoardView />}
            /> */}
            {/* <Route
              path='spaces/:id/:filter/:feedType'
              element={<BoardView />}
            /> */}
            <Route
              path='spaces/:id/:filter/:feedType'
              element={<BoardView />}
            />
            <Route path='spaces/:id/:filter' element={<BoardView />} />
            <Route path='spaces/:id' element={<BoardView />} />
            {isUnifiedEnabled() && (
              <>
                <Route
                  path='folders'
                  element={
                    <SpaceContentListing
                      tabDetails={{
                        content_addition_allowed: false,
                        content_preview_listing: false,
                        content_type: 'listing',
                        kind: 'folders',
                        search_required: true,
                      }}
                      containerClass=''
                      filter={'folders'}
                    />
                  }
                />
                <Route
                  path={`folders/${MY_FOLDER_FILES}`}
                  exact
                  element={<DynamicFolderTemplateView />}
                />
                <Route
                  path={`folders/${MY_FOLDER_LINKS}`}
                  exact
                  element={<DynamicFolderTemplateView />}
                />
                <Route path='folders/:id/:filter' element={<BoardView />} />
                <Route path='folders/:id' element={<BoardView />} />
                <Route path='inbox'>
                  <Route path='my/tasks' element={<TasksInbox />} />
                  <Route path='my/groups' element={<GroupsInbox />} />
                  <Route
                    path={'team/partners'}
                    element={<TeamPartnersInbox />}
                  />
                  <Route path={'my/patients'} element={<PatientsInbox />} />
                  <Route path={'my/partners'} element={<PartnersInbox />} />
                  <Route
                    path={'team/patients'}
                    element={<TeamPatientsInbox />}
                  />
                  <Route
                    path='my/conversations'
                    element={<ConversationsInbox />}
                  />
                  <Route path='*' element={<></>} />
                </Route>
                <Route path='patient/activity' element={<></>} />
              </>
            )}
          </Route>
          <Route element={<ThreeColumnLayout />}>
            <Route
              path={'no-partners'}
              exact={true}
              element={<NoPartnerSpace />}
            />
            <Route
              path={'partners/:id?'}
              exact={true}
              element={<PartnerSpace />}
            />
            <Route path='team' exact={true} element={<TeamSpace />} />
            <Route
              path='team/group/:id'
              element={
                <NonSubscribedView headerNotRequired={true}>
                  <GroupMessenger />
                </NonSubscribedView>
              }
            />
            <Route
              path='team/dm/:id'
              element={
                <NonSubscribedView headerNotRequired={true}>
                  <TeamSpaceCommunicationsView />
                </NonSubscribedView>
              }
            />
            <Route
              path='team/dm/:id/:filter'
              element={<TeamSpaceCommunicationsView />}
            />
          </Route>
          <Route path='not_found' element={<ResourceUnavailableView />} />
          <Route path='policies/ccpa' element={<CCPAPolicyView />} />
          <Route
            path='private_access_denied'
            element={<ResourceUnavailableView />}
          />
          <Route path='cases/:id/:additionalFilter' element={<CaseView />} />
          <Route
            path='cases/:id/:additionalFilter/:additionalInfo'
            exact={true}
            element={<CaseView />}
          />
          <Route path='cases/:id' exact={true} element={<CaseView />} />
          <Route
            path='folders/my_files'
            exact
            element={<DynamicFolderTemplateView />}
          />
          <Route
            path='folders/my_cases'
            exact
            element={<DynamicFolderTemplateView />}
          />
          <Route
            path='folders/my_links'
            exact
            element={<DynamicFolderTemplateView />}
          />
          <Route
            path='spaces/:id/shared/:token'
            element={<AuthenticateSharedSpace />}
          />
          <Route element={<ThreeColumnLayout />}>
            <Route path={'tasks/*'} element={<BaseTaskManger />} />
          </Route>
          <Route
            path='terminate_session/:user_id/:device_token'
            element={<TerminateSession />}
          />
          <Route path='designs/*' exact element={<Designs />} />
          <Route path='designs' exact element={<Designs />} />
          <Route path='default' element={<RedirectToDefault />} />
          <Route
            path='account_settings/*'
            exact
            element={<AccountSettingView />}
          />
          <Route
            path='account_settings'
            exact
            element={<AccountSettingView />}
          />
          <Route path='settings/*' exact element={<WorkspaceSettingView />} />
          <Route path='settings' exact element={<WorkspaceSettingView />} />
          <Route
            path='download_assets/:query_by/:id'
            element={<DownloadAsset />}
          />
          <Route path='comments/:id' element={<CommentView />} />
          <Route path='users/:id/' exact element={<UserView />} />
          <Route path='users/:id/*' exact element={<UserView />} />
          <Route
            path='users/:id/edit'
            exact
            element={<EditUserProfile floaterMessageNotRequired={true} />}
          />
          <Route
            path='companies/:id/:filter/:additionalFilter'
            element={<BoardView />}
          />
          <Route path='companies/:id/:filter' element={<BoardView />} />
          <Route path='companies/:id' element={<BoardView />} />
          <Route
            path='clinics/:id/:filter/:additionalFilter'
            element={<BoardView />}
          />
          <Route path='clinics/:id/:filter' element={<BoardView />} />
          <Route path='clinics/:id' element={<BoardView />} />
          <Route
            path='team/spaces/:id/:filter/:additionalFilter'
            element={<BoardView />}
          />
          <Route path='team/spaces/:id/:filter' element={<BoardView />} />
          <Route path='team/spaces/:id' element={<BoardView />} />
          <Route path='folders/:id/:filter' element={<BoardView />} />
          <Route path='folders/:id' element={<BoardView />} />
          {workspace.profile_switcher_enabled && (
            <Route
              path='profile/reset_password'
              element={<ProfileResetPassword title={'Reset'} />}
            />
          )}
          {workspace.profile_switcher_enabled && (
            <Route
              path='profile/set_password'
              element={<ProfileSetAndUpdateForm />}
            />
          )}
          {workspace.profile_switcher_enabled && (
            <Route
              path='profile/update_pin'
              element={<ProfileResetPassword title={'Update'} />}
            />
          )}
          {workspace.profile_switcher_enabled && (
            <Route path='profile/switcher' element={<SwitchProfile />} />
          )}
          <Route path='*' element={<WorkspaceDefaultRoutes />} />
          <Route path='logout' element={<Logout />} />
        </Route>
        {directWorkSpaceLogin.isDirectWorkSpaceLogin &&
        workspace.profile_switcher_enabled ? (
          <Route
            path='*'
            element={
              <Navigate to={`${workspace.identifier}/profile/switcher`} />
            }
          />
        ) : (
          <Route path='*' element={<Navigate to={redirectPath} />} />
        )}
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path='*' element={<Navigate to={'/'} />} />
      </Routes>
    );
  }
};

const WorkSpaceViewRoutesContainer = connect(
  ({ currentUser, workspace }) => ({ currentUser, workspace }),
  { setSource },
)(WorkSpaceViewRoutes);

export default WorkSpaceViewRoutesContainer;
