import React from 'react';
import { Table, Card } from 'react-bootstrap';
import './style.scss';

const OsTable = ({ columns, data, loading, getRowStyle }) => {
  if (loading) {
    return <div className='table-loading'>Loading...</div>;
  }

  return (
    <div className='table-responsive'>
      <Card>
        <Table hover>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className='text-center'>
                  No data available
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr
                  key={item.id || index}
                  style={getRowStyle ? getRowStyle(item) : {}}>
                  {columns.map((column) => (
                    <td key={`${item.id}-${column.key}`}>
                      {column.render ? column.render(item) : item[column.key]}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default OsTable;
