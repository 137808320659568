import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { flashError } from 'app/actions/flashMessage';
import { upperFirst } from 'app/utils/osLodash';
import { useDispatch } from 'react-redux';

const ADD_INVOICE = queryLoader(
  'app/graphql/mutations/CareInvoice/AddNewCareInvoice.gql',
);

const ERROR_MESSAGE =
  'Error adding invoice, Please try again or Contact Support';

export const useAddNewInvoice = () => {
  const dispatch = useDispatch();
  const [invoice, setInvoice] = useState(null);
  const [addInvoiceMutation, { loading }] = useMutation(ADD_INVOICE, {
    refetchQueries: ['CareInvoices'],
  });

  const addInvoice = useCallback(
    (variables) => {
      return addInvoiceMutation({
        variables,
      }).then(({ data }) => {
        if (data.addCareInvoice.success) {
          setInvoice(data.addCareInvoice.entity);
          return {
            success: true,
          };
        } else {
          const error = data.addCareInvoice.errors?.[0];
          const errorMessage = error
            ? `${upperFirst(error.field.replaceAll('_', ' '))} ${error.message}`
            : ERROR_MESSAGE;
          dispatch(flashError(errorMessage, false));
          return { success: false };
        }
      });
    },
    [addInvoiceMutation, dispatch, setInvoice],
  );

  return {
    addInvoice,
    loading,
    invoice,
  };
};
