import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import OsBtn from 'app/components/OsBtn';
import { dateWithFormat } from 'app/utils/timeHelper';
import { useAddNewInvoice } from 'app/hooks/careSpaces/useAddNewInvoice';
import { useGetContracts } from 'app/hooks/careSpaces/useGetContracts';
import './style.scss';

const InvoiceModal = ({ onClose, onSubmit, spaceId }) => {
  const [selectedContract, setSelectedContract] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [amount, setAmount] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [notifyPatient, setNotifyPatient] = useState(false);
  const [notes, setNotes] = useState('');
  const { addInvoice } = useAddNewInvoice();
  const { contracts } = useGetContracts(spaceId);

  const isFormValid = () => {
    return (
      selectedContract && invoiceDate && dueDate && amount > 0 && invoiceNumber
    );
  };

  const handleSubmit = () => {
    const variables = {
      careSpaceId: String(spaceId),
      contractId: selectedContract,
      date: dateWithFormat(invoiceDate, 'YYYY-MM-DD'),
      dueDate: dateWithFormat(dueDate, 'YYYY-MM-DD'),
      amount: parseFloat(amount),
      invoiceNumber: invoiceNumber,
      notifyPatient: notifyPatient,
      notes: notes.trim() || null,
    };

    addInvoice(variables).then(({ success }) => {
      if (success) {
        onClose();
      }
    });
  };

  return (
    <Modal show={true} onHide={onClose} centered className='care-invoice-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Add Invoice</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className='row'>
          <Form.Group className='mb-3'>
            <Form.Label>Contract*</Form.Label>
            <Form.Select
              className='contract-select'
              value={selectedContract}
              onChange={(e) => setSelectedContract(e.target.value)}>
              <option value=''>Select a contract</option>
              {contracts.map((contract) => (
                <option key={contract.id} value={contract.id}>
                  {contract.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className='mb-3 col-md-6'>
            <Form.Label>Invoice Number*</Form.Label>
            <Form.Control
              type='text'
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              placeholder='INV-1234'
            />
          </Form.Group>

          <Form.Group className='mb-3 col-md-6'>
            <Form.Label>Amount*</Form.Label>
            <Form.Control
              type='number'
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder='1000'
            />
          </Form.Group>

          <Form.Group className='mb-3 col-md-6'>
            <Form.Label>Invoice Date*</Form.Label>
            <Form.Control
              type='date'
              value={invoiceDate.toISOString().split('T')[0]}
              onChange={(e) => setInvoiceDate(new Date(e.target.value))}
            />
          </Form.Group>

          <Form.Group className='mb-3 col-md-6'>
            <Form.Label>Due Date*</Form.Label>
            <Form.Control
              type='date'
              value={dueDate.toISOString().split('T')[0]}
              onChange={(e) => setDueDate(new Date(e.target.value))}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              className='notes-input'
              as='textarea'
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder='Add any additional notes (optional)'
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Check
              type='checkbox'
              label='Notify Patient'
              checked={notifyPatient}
              onClick={() => setNotifyPatient(!notifyPatient)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <OsBtn
          text='Cancel'
          onClick={onClose}
          extraClass='btn-secondary'
          name='BtnSecondary'
        />
        <OsBtn
          text='Submit Invoice'
          onClick={handleSubmit}
          disabled={!isFormValid()}
          extraClass='btn-primary'
          name='BtnPrimary'
        />
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceModal;
