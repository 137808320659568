import React, { Component } from 'react';
import { connect } from 'react-redux';

import { keys, some } from 'app/utils/osLodash';

const PULSE_INDEX_COMPONENT_MAPPER = {
  PulseHeader: {
    mobile: '12 mb-3',
    ipad: '12 px-0 mb-26',
    desktop: 'col-12 px-0 mb-26',
  },

  RightSidebar: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': '12',
    'ipad:landscape': 'lg-4 col-pl-8',
    desktop: 'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 ',
  },

  FeedBlock: {
    'mobile:portrait': '12 px-0',
    'mobile:landscape': '12',
    'ipad:portrait': '12 position-unset',
    'ipad:landscape': 'lg-8 position-unset col-pr-8',
    desktop: 'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 position-unset',
  },

  SpaceIndexWithWidget: {
    'mobile:portrait': '12',
    'mobile:landscape': '12',
    'ipad:portrait': '12 position-unset',
    'ipad:landscape': 'lg-8 position-unset col-pr-8 space-index-8',
    'desktop-md': 'col-xl-8 col-lg-8',
    desktop:
      'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 position-unset col-px-8 space-index-8 ps-0',
  },

  SpaceIndexWithoutWidget: {
    'mobile:portrait': '12',
    'mobile:landscape': '12',
    'ipad:portrait': '12 position-unset',
    'ipad:landscape': 'lg-8 position-unset col-pr-8',
    desktop:
      'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 position-unset mb-4',
  },
  RecommendedSpace: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 mb-4',
    'ipad:landscape': '12 mb-4',
    desktop: 'col-lg-12 col-12 col-md-6 mb-4',
  },
  SpaceList: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 mb-4',
    'ipad:landscape': '4 mb-4',
    desktop: 'col-4 mb-4',
  },
  MySpaceList: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 mb-4',
    'ipad:landscape': '4 mb-4',
    desktop: 'col-4 col-xxl-4 mb-4',
  },
  MySpaceListWithWidget: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 mb-4',
    'ipad:landscape': '6 mb-4',
    desktop: 'col-6 col-xxl-4 mb-4',
  },
  MySpaceListWithoutWidget: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 mb-4',
    'ipad:landscape': '4 mb-4',
    desktop: 'col-4 col-xxl-4 mb-4',
  },
};

const USER_PROFILE_COMPONENT_MAPPER = {
  AvatarContainer: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': 'sm-2',
    'ipad:landscape': 'md-2 px-0',
    desktop: 'col-lg-2 col-md-3 col-sm-12 ps-0',
  },

  UserInfoContainer: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': 'sm-10 ',
    'ipad:landscape': 'md-10',
    desktop: 'col-lg-10 col-md-9 col-sm-12',
  },

  OtherUserAvatarContainer: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': 'sm-3',
    'ipad:landscape': 'md-2 px-0',
    desktop: 'col-lg-2 col-md-3 col-sm-12 px-0',
  },

  OtherUserInfoContainer: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': 'sm-9 ',
    'ipad:landscape': 'md-10',
    desktop: 'col-lg-10 col-md-9 col-sm-12 px-0',
  },

  TimelineRightSidebar: {
    mobile: 'sm-12 px-0',
    'ipad:landscape': 'lg-4 md-4',
    desktop: 'col-md-4 col-12',
  },

  TimelineRightSidebarCard: {
    mobile: 'sm-12 px-0 mb-3',
    'ipad:portrait': '6 mb-3 ipad-port-card-100',
    'ipad:landscape': '12 mb-3',
    desktop: 'col-xl-12 col-lg-12 col-md-6 col-sm-12 mb-3',
  },

  Timeline: {
    mobile: '12 px-0 user-right-section',
    'ipad:portrait': '12 px-0',
    'ipad:landscape': 'lg-8',
    desktop: 'col-md-8 col-12 ps-0',
  },
};

const EDIT_BOARD_COMPONENT_MAPPER = {
  editRenderCard: {
    'mobile:portrait': '12',
    'mobile:landscape': '6',
    'ipad:portrait': '6',
    'ipad:landscape': '4',
    desktop: 'col-12 col-md-6 col-lg-4',
  },
};

const USER_ACCOUNT_COMPONENT_MAPPER = {
  UserTabData: {
    'mobile:portrait': '12 mb-3',
    'mobile:landscape': '6 mb-3',
    ipad: '6 mb-3',
    desktop: 'col-lg-4 col-md-6 col-sm-12 mb-4',
  },

  InviteTableData: {
    'mobile:portrait': '12 order-1',
    'mobile:landscape': '8 mb-2',
    ipad: '8',
    desktop: 'col-lg-8 col-md-8 col-sm-7 mb-4',
  },

  InviteSticky: {
    'mobile:portrait': '12',
    'mobile:landscape': '4 invitee-sticky-require',
    ipad: '4 col-md-4',
    desktop: 'col-lg-4 col-md-4 col-sm-5',
  },

  CountryField: {
    'mobile:portrait': '12',
    'mobile:landscape': '6',
    ipad: '6',
    desktop: 'col-md-6',
  },

  StateField: {
    'mobile:portrait': '12',
    'mobile:landscape': '6',
    ipad: '6',
    desktop: 'col-md-6',
  },

  CityField: {
    mobile: '6',
    ipad: '6',
    desktop: 'col-md-6',
  },

  ZipField: {
    mobile: '6',
    ipad: '6',
    desktop: 'col-md-6',
  },

  CardState: {
    mobile: '3',
    'ipad:portrait': '3',
    'ipad:landscape': '4',
    desktop: 'col-3',
  },

  CardZipCode: {
    mobile: '3',
    'ipad:portrait': '3',
    'ipad:landscape': '2',
    desktop: 'col-3',
  },

  CardNumber: {
    'mobile:portrait': '12',
    'mobile:landscape': '6',
    ipad: '6',
    desktop: 'col-6',
  },

  CardExpiry: {
    'mobile:portrait': '6',
    'mobile:landscape': '3',
    ipad: '3',
    desktop: 'col-3',
  },

  CardCvc: {
    'mobile:portrait': '6',
    'mobile:landscape': '3',
    ipad: '3',
    desktop: 'col-3',
  },

  CardHolderName: {
    'mobile:portrait': '7',
    'mobile:landscape': '9',
    ipad: '9',
    desktop: 'col-9',
  },

  PostalCode: {
    'mobile:portrait': '5',
    'mobile:landscape': '3',
    ipad: '3',
    desktop: 'col-3 col-sm-3',
  },
};

const ONBOARDING_COMPONENT_MAPPER = {
  gridCol: {
    'mobile:portrait': '12',
    'mobile:landscape': '6',
    ipad: '6',
    desktop: 'col-md-6',
  },

  GridColVerifyPassword: {
    'mobile:portrait': '12 pt-3',
    'mobile:landscape': '6',
    ipad: '6',
    desktop: 'col-md-6',
  },
};
const LANDING_PAGE_COMPONENT_MAPPER = {
  HeadingText: {
    'mobile:portrait': '10 px-0',
    'mobile:landscape': '6',
    'ipad:portrait': 'md-10',
    'ipad:landscape': '5 pe-5 ps-0',
    desktop: 'col-lg-5 col-md-4',
  },

  SvgContainer: {
    'mobile:portrait': 'sm-12 px-0 mt-48 mb-57',
    'mobile:landscape': '6',
    'ipad:portrait': 'md-12 mt-48 mb-80 px-0',
    'ipad:landscape': '7 px-0',
    desktop: 'col-lg-7 col-md-8',
  },

  SectionBottom: {
    mobile: 'sm-12 text-center',
    ipad: 'md-12 text-center',
    desktop: 'col-lg-6 col-md-12 col-12 text-center',
  },
};

const DETAIL_BOARD_COMPONENT_MAPPER = {
  HeaderImage: {
    mobile: '12',
    'mobile:landscape': '4',
    'ipad:portrait': '3',
    'ipad:landscape': 'md-3',
    desktop: 'col-12 col-sm-3',
  },

  HeaderDetail: {
    mobile: '12',
    'mobile:landscape': '8',
    'ipad:portrait': '9',
    'ipad:landscape': 'md-9',
    desktop: 'col-12 col-sm-9',
  },

  InformationFullView: {
    mobile: '12 px-0 user-right-section',
    'ipad:portrait': '12 px-0',
    'ipad:landscape': 'lg-12 px-0',
    desktop: 'col-12 px-0',
  },

  TimelineRightSidebar: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': '12',
    'ipad:landscape': 'lg-4 md-4',
    desktop: 'col-xl-4 col-lg-4 col-md-12 col-sm-12',
  },

  Timeline: {
    mobile: '12 px-0 user-right-section',
    'ipad:portrait': '12 px-0',
    'ipad:landscape': 'lg-8',
    desktop: 'col-xl-8 col-lg-8 col-md-12 col-sm-12',
  },

  TimelineRightSidebarCard: {
    mobile: 'sm-12 px-0 mb-3',
    'ipad:portrait': '6 mb-3 ipad-port-card-100',
    'ipad:landscape': '12 mb-3',
    desktop: 'col-xl-12 col-lg-12 col-md-6 col-sm-12 mb-3',
  },

  ExtraInfo: {
    mobile: 'sm-12 px-0 mb-4',
    'ipad:portrait': 'sm-4 mb-4 extrainfo-sponsor-col col-ipad768-50',
    'ipad:landscape': 'lg-6 col-md-6 mb-4',
    desktop: 'col-xxl-4 col-lg-6 col-md-6 col-sm-12 mb-4',
  },
};

const CONTRACT_CARD_COMPONENT_MAPPER = {
  Cardcols: {
    'mobile:portrait': '12',
    'mobile:landscape': '12',
    'ipad:portrait': 'lg-12 col-md-12',
    'ipad:landscape': 'md-12',
    desktop: 'col-xl-12 col-lg-12 col-md-12 col-xxl-12',
  },
};

const DISCUSSION_CARD_COMPONENT_MAPPER = {
  Cardcols: {
    'mobile:portrait': '12',
    'mobile:landscape': '6',
    'ipad:portrait': 'lg-4 col-md-6',
    'ipad:landscape': 'md-6',
    desktop: 'col-xl-6 col-lg-6 col-md-6 col-xxl-6',
  },
};

const PULSE_DISCUSSION_CARD_COMPONENT_MAPPER = {
  Cardcols: {
    'mobile:portrait': '12 pulse-entity-card',
    'mobile:landscape': '6',
    'ipad:portrait': 'lg-4 col-md-6 pulse-entity-card',
    'ipad:landscape': 'md-6 pulse-entity-card',
    desktop: 'col-xl-6 col-lg-6 col-md-6 col-xxl-6 pulse-entity-card',
  },
};

const FEED_CARD_COLUMN_COMPONENT_MAPPER = {
  Cardcols: {
    'mobile:portrait': '12 tab-card-col',
    'mobile:landscape': 'sm-9 tab-card-col',
    'ipad:portrait': 'md-6 tab-card-col',
    'ipad:landscape': 'md-6 tab-card-col',
    desktop: 'col-xxl-6 col-xl-6 col-lg-6 col-md-6 tab-card-col',
  },
};

const TRENDING_CARD_COLUMN_COMPONENT_MAPPER = {
  Cardcols: {
    'mobile:portrait': '12 tab-card-col',
    'mobile:landscape': 'sm-9 tab-card-col',
    'ipad:portrait': 'md-6 tab-card-col',
    'ipad:landscape': 'md-6 tab-card-col',
    desktop: 'col-xxl-6 col-xl-4 col-lg-6 col-md-6 tab-card-col',
  },
};

const TIMELINE_SPACE_COMMENT_CARD_COLUMN_COMPONENT_MAPPER = {
  Cardcols: {
    'mobile:portrait': '12 tab-card-col',
    'mobile:landscape': 'sm-6 tab-card-col',
    'ipad:portrait': 'lg-6 col-md-6 tab-card-col',
    'ipad:landscape': 'md-6 tab-card-col',
    desktop: 'col-xxl-6 col-xl-6 col-lg-6 col-md-6 tab-card-col',
  },
};

const RELATED_CASE_COMPONENT_MAPPER = {
  RelatedCase: {
    'mobile:portrait': '12 mb-2',
    'mobile:landscape': '5 mb-2',
    'ipad:portrait:mini': '6 mb-2',
    'ipad:portrait:pro': '5 mb-2',
    'ipad:landscape': '12 mb-2',
    desktop: 'col-lg-12 mb-2',
  },

  CardCols: {
    'mobile:portrait': '12',
    'mobile:landscape': '5 ',
    'ipad:portrait:mini': '5 ',
    'ipad:portrait:pro': '5 mb-2',
    'ipad:landscape': '5 mb-2',
    desktop: 'col-sm-6 col-lg-4 mb-2',
  },
};

const RELATED_BOARD_COMPONENT_MAPPER = {
  RelatedBoard: {
    'mobile:portrait': '12 mb-2',
    'mobile:landscape': '5 mb-2',
    'ipad:portrait:mini': '6 mb-2',
    'ipad:portrait:pro': '5 mb-2',
    'ipad:landscape': '12 mb-2',
    desktop: 'col-lg-12 mb-2',
  },
};

const RELATED_COMPARISON_COMPONENT_MAPPER = {
  RelatedComparison: {
    'mobile:portrait': '12',
    'mobile:landscape': '5',
    'ipad:portrait': '6',
    'ipad:landscape': '12 mb-4',
    desktop: 'col-lg-12 mb-4',
  },
};

const CARD_GRID_COMPONENT_MAPPER = {
  Card: {
    'mobile:portrait': '12 mb-4 ',
    'ipad:portrait:mini': 'md-6 mb-4',
    'ipad:portrait:pro': 'md-4 mb-4',
    'ipad:landscape:mini': 'md-4 mb-4',
    'ipad:landscape:pro': 'md-4 mb-4',
    desktop: 'col-lg-4 col-md-6 col-6 mb-4',
  },

  CardSpaceList: {
    'mobile:portrait': '12 mb-4 ',
    'ipad:portrait:mini': 'md-6 mb-4',
    'ipad:portrait:pro': 'md-4 mb-4',
    'ipad:landscape:mini': 'md-6 mb-4',
    'ipad:landscape:pro': 'md-4 mb-4',
    desktop: 'col-lg-4 col-md-6 mb-4',
  },
};

const SPACE_DETAIL_COMPONENT_MAPPER = {
  Card: {
    'mobile:portrait': '12 mb-4',
    'ipad:portrait': 'md-6 col-lg-4 mb-4',
    'ipad:landscape:mini': 'md-4 mb-4',
    'ipad:landscape:pro': 'md-4 mb-4',
    desktop: 'col-xxl-3 col-lg-4 col-md-4 col-6 mb-4 ',
  },

  ThreeColCard: {
    'mobile:portrait': '12 mb-4',
    'ipad:portrait': 'md-6 col-lg-6 mb-4',
    'ipad:landscape:mini': 'md-6 mb-4',
    'ipad:landscape:pro': 'md-4 mb-4',
    desktop: 'col-xxl-3 col-lg-3 col-md-6 col-6 mb-4 ',
  },
};

const SPACE_CREATE_INDEX_COMPONENT_MAPPER = {
  Admin: {
    'mobile:portrait': '12 px-0 mb-0',
    'ipad:portrait': '12',
    'ipad:landscape': 'lg-7 mb-4',
    desktop:
      'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 position-unset mb-4',
  },

  Editor: {
    'mobile:portrait': '12 px-0 mb-0',
    'ipad:portrait': '12',
    'ipad:landscape': 'lg-12 mb-4',
    desktop:
      'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 position-unset mb-4',
  },

  FullScreen: {
    'mobile:portrait': '12 px-0 mb-0',
    'ipad:portrait': '12',
    'ipad:landscape': 'lg-12 mb-4',
    desktop: 'col-12 position-unset mb-4',
  },

  ListType: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': '12',
    'ipad:landscape': 'lg-5 desktop-landscape-only-col',
    desktop: 'col-lg-4 col-md-12 col-sm-12 desktop-landscape-only-col',
  },
};

const SPACE_INDEX_COMPONENT_MAPPER = {
  RightSidebar: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': '12',
    'ipad:landscape': 'lg-4 col-pl-8',
    desktop: 'col-xxl-3 col-xl-3 col-lg-4 col-md-12 ms-auto col-px-8',
  },

  FeedBlock: {
    'mobile:portrait': '12 px-0',
    'mobile:landscape': '12',
    'ipad:portrait': '12 position-unset',
    'ipad:landscape': 'lg-8 position-unset col-pr-8',
    desktop: 'col-md-8 col-12 position-unset',
  },

  SpaceIndexWithWidget: {
    'mobile:portrait': '12',
    'mobile:landscape': '12',
    'ipad:portrait': '12 position-unset',
    'ipad:landscape': 'lg-8 position-unset col-pr-8 space-index-8',
    desktop:
      'col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-sm-12 position-unset col-px-8 space-index-8',
  },

  SpaceIndexWithoutWidget: {
    'mobile:portrait': '12',
    'mobile:landscape': '12',
    'ipad:portrait': '12 position-unset',
    'ipad:landscape': 'lg-8 position-unset col-pr-8',
    desktop:
      'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 position-unset mb-4',
  },
  RecommendedSpace: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 mb-4',
    'ipad:landscape': '12 mb-4',
    desktop: 'col-lg-12 col-12 col-md-6 mb-4',
  },
  SpaceList: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 col-lg-4 mb-4',
    'ipad:landscape': '4 mb-4',
    desktop: 'col-12 col-md-6 col-lg-4 mb-4',
  },
  MySpaceList: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 col-lg-4 mb-4',
    'ipad:landscape': '4 mb-4',
    desktop: 'col-12 col-md-6 col-lg-4 mb-4',
  },

  MySpaceListWithWidget: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 col-lg-4 mb-4',
    'ipad:landscape': '4 mb-4',
    desktop: 'col-6 col-md-6 col-lg-4 mb-4',
  },
  MySpaceListWithoutWidget: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 col-lg-4 mb-4',
    'ipad:landscape': '4 mb-4',
    desktop: 'col-12 col-md-6 col-lg-4 mb-4',
  },
};

const CASE_DETAIL_COMPONENT_MAPPER = {
  CaseLeftSection: {
    'mobile:portrait': '12 px-0',
    'ipad:portrait': '12 px-0',
    'ipad:landscape': 'md-8 ps-0',
    desktop: 'col-md-8 col-12 ps-0',
  },

  CaseRightSection: {
    mobile: '12',
    'ipad:portrait': '12 px-0 px-4 order-3',
    'ipad:landscape': 'md-4 pe-0',
    desktop: 'col-md-4 col-12 pe-0 ',
  },
};

const DETAIL_TOOL_COMPONENT_MAPPER = {
  HeaderImage: {
    mobile: '12',
    'mobile:landscape': '4',
    'ipad:portrait': '3',
    'ipad:landscape': 'md-3',
    desktop: 'col-12 col-sm-3',
  },

  HeaderDetail: {
    mobile: '12',
    'mobile:landscape': '8',
    'ipad:portrait': '9',
    'ipad:landscape': 'md-9',
    desktop: 'col-12 col-sm-9',
  },

  TimelineRightSidebar: {
    mobile: 'sm-12 px-0',
    'ipad:landscape': 'lg-4 md-4',
    desktop: 'col-xl-4 col-lg-4 col-md-12 col-sm-12',
  },

  Timeline: {
    mobile: '12 px-0 user-right-section',
    'ipad:portrait': '12 px-0',
    'ipad:landscape': 'lg-8',
    desktop: 'col-xl-8 col-lg-8 col-md-12 col-sm-12',
  },
  TimelineRightSidebarCard: {
    mobile: 'sm-12 px-0 mb-3',
    'ipad:portrait': 'sm-6 mb-3',
    'ipad:landscape': '12 mb-3',
    desktop: 'col-xl-12 col-lg-12 col-md-6 col-sm-12 mb-3',
  },
};

const RECENT_DISCUSSION_COMPONENT_MAPPER = {
  CardCols: {
    mobile: '12',
    'mobile:landscape': '12',
    'ipad:portrait': '12',
    'ipad:landscape': '12',
    desktop: 'col-12 px-0',
  },
};

const DETAIL_RIGHTSIDEBAR_COMPONENT_MAPPER = {
  RecentDiscussionsCol: {
    'mobile:portrait': '12',
    'ipad:portrait': '6',
    'ipad:landscape': '12 p-0',
    desktop: 'col-12 px-0',
  },

  UpcomingEventCol: {
    'mobile:portrait': '12',
    'ipad:portrait': '6 ',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },

  SponsoredCourseBlock: {
    'mobile:portrait': '12',
    'ipad:portrait': '6',
    'ipad:landscape': '12',
    desktop: 'col-12 px-0',
  },

  LearningHubBlock: {
    'mobile:portrait': '12 px-0',
    'ipad:portrait': '6',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },

  EtiqutteBlock: {
    'mobile:portrait': '12',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0 EtiqutteBlock',
  },

  InformationBlock: {
    'mobile:portrait': '12 px-0',
    'ipad:portrait': '6 ps-0 ',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },

  TipSideBarBlock: {
    'mobile:portrait': '12',
    'ipad:portrait': '6 ',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },

  DoctorConnectBlock: {
    'mobile:portrait': '12 px-0',
    'ipad:portrait': '6 ',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },

  CareTeamBlock: {
    'mobile:portrait': '12 px-0',
    'ipad:portrait': '6 ',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },

  CaseDocumentsList: {
    'mobile:portrait': '12 px-0',
    'ipad:portrait': '6 ps-0',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },

  CaseDocumentsCard: {
    'mobile:portrait': '12 mb-2',
    'mobile:landscape': '5 mb-2',
    'ipad:portrait:mini': '6 mb-2',
    'ipad:portrait:pro': '5 mb-2',
    'ipad:landscape': '12 mb-2',
    desktop: 'col-lg-12 mb-2',
  },

  PopularHashTagBlock: {
    'mobile:portrait': '12 px-0 mobile-hashtag',
    'ipad:portrait': '6 ps-0',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },
  CourseKeyBlock: {
    'mobile:portrait': '12',
    'ipad:portrait': '6 ',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },

  SkillGainBlock: {
    'mobile:portrait': '12 px-0 mobile-tag',
    'ipad:portrait': '12',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },

  TestimonialBlock: {
    'mobile:portrait': '12 px-0',
    'ipad:portrait': '12 ',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0',
  },
};

const COURSE_CARD_BUY_COMPONENT_MAPPER = {
  LeftCol: {
    'mobile:portrait': '12 order-2 px-0',
    'ipad:portrait': '8',
    'ipad:landscape': '8',
    desktop: 'col-xl-8 col-lg-7 col-md-7 mb-5',
  },

  RightCol: {
    'mobile:portrait': '12 px-0',
    'ipad:portrait': '4',
    'ipad:landscape': '4',
    desktop: 'col-xl-4 col-lg-5 col-md-5',
  },
};

const APPOINTMENT_MODAL_COMPONENT_MAPPER = {
  CardCols: {
    'mobile:portrait': '12 mb-3',
    'mobile:landscape': '6 mb-3',
    'ipad:portrait': 'lg-4 col-md-6 mb-3',
    'ipad:landscape': 'md-4 mb-3',
    desktop: 'col-12 col-md-6 col-lg-4 col-sm-6 col-xxl-4 mb-3',
  },
};

const RELATED_CARESPACE_MODAL_COMPONENT_MAPPER = {
  CardCols: {
    'mobile:portrait': '12',
    'ipad:portrait': 'col-md-6',
    'ipad:landscape': 'md-6',
    desktop: 'col-6',
  },
};

const CASE_TREATMENT_LINK_MAPPER = {
  CsTreatmentLink: {
    mobile: '12 px-0 ',
    'ipad:portrait': '6 pe-md-2',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0 ',
  },

  CsTreatmentInfo: {
    mobile: '12 ',
    'ipad:portrait': '6 ps-md-3',
    'ipad:landscape': '12 px-0',
    desktop: 'col-12 px-0 ',
  },
};

const COMMENT_SECTION_COMPONENT_MAPPER = {
  CommentSectionCol: {
    mobile: '12 px-0',
    'ipad:portrait': '12 col-tablet-12',
    'ipad:landscape': '8 col-tablet-12',
    desktop: 'col-lg-8 col-md-12 ',
  },

  CaseCommentSectionCol: {
    mobile: '12 px-0',
    'ipad:portrait': '12 col-tablet-12',
    'ipad:landscape': 'md-8 col-tablet-12',
    desktop: 'col-md-8 col-12 ',
  },

  CommentRightSidebarCol: {
    mobile: '12 px-0',
    'ipad:portrait': '12 col-tablet-12',
    'ipad:landscape': '4 col-tablet-12',
    desktop: 'col-lg-4 col-md-12',
  },

  CaseCommentRightSidebarCol: {
    mobile: '12 px-0',
    'ipad:portrait': '12 col-tablet-12 px-0',
    'ipad:landscape': 'md-4 col-tablet-12',
    desktop: 'col-md-4 col-12',
  },
};

const APPOINTEMNT_SUMMARY_COMPONENT_MAPPER = {
  CardCol: {
    mobile: '12 mb-3',
    'ipad:portrait': '6 mb-3',
    'ipad:landscape': '12 mb-3',
    desktop: 'col-12 mb-3',
  },
};

const SPACE_CLV_COMPONENT_MAPPER = {
  LinkCards: {
    mobile: '12 mb-2',
    'ipad:portrait': '12 mtb-2',
    'ipad:landscape': '12 mb-2',
    desktop: 'col-12 mb-2',
  },
};

const CARE_SPACE_COMPONENT_MAPPER = {
  CardCols: {
    'mobile:portrait': '12 mb-3 ',
    'mobile:landscape': 'sm-9 ',
    'ipad:portrait': 'md-6 col-lg-4 mb-4',
    'ipad:landscape': 'md-6 col-lg-4 mb-4',
    desktop: 'col-12 col-md-6 col-lg-4 mb-4 col-sm-6 col-xxl-3 ',
  },

  RightSidebar: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': '12',
    'ipad:landscape': 'lg-4 col-pl-8',
    desktop: 'col-md-4 col-12 ms-auto col-px-8',
  },

  FeedBlock: {
    'mobile:portrait': '12 px-0',
    'mobile:landscape': '12',
    'ipad:portrait': '12 position-unset',
    'ipad:landscape': 'lg-8 position-unset col-pr-8',
    desktop: 'col-md-8 col-12 position-unset',
  },

  RightSidebarWidget: {
    'mobile:portrait': '12 mb-2',
    'mobile:landscape': '5 mb-2',
    'ipad:portrait:mini': '6 mb-2',
    'ipad:portrait:pro': '5 mb-2',
    'ipad:landscape': '12 mb-2',
    desktop: 'col-lg-12 mb-2',
  },

  PatientCardRightSidebarWidget: {
    'mobile:portrait': '12 mb-1',
    'mobile:landscape': '12 mb-1',
    'ipad:portrait:mini': '6 mb-1',
    'ipad:portrait:pro': '6 mb-1',
    'ipad:landscape': '6 mb-1',
    desktop: 'col-lg-12 mb-1',
  },

  AssociatedClinicWidget: {
    'mobile:portrait': '12 mb-2 px-0',
    'mobile:landscape': '5 mb-2 px-0',
    'ipad:portrait:mini': '6 mb-2 px-0',
    'ipad:portrait:pro': '5 mb-2 px-0',
    'ipad:landscape': '12 mb-2 px-0',
    desktop: 'col-lg-12 mb-2 px-0',
  },

  AvatarContainer: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': 'sm-2',
    'ipad:landscape': 'md-2 px-0',
    desktop: 'col-lg-3 col-md-4 col-sm-12',
  },

  UserInfoContainer: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': 'sm-10 ',
    'ipad:landscape': 'md-10',
    desktop: 'col-lg-9 col-md-8 col-sm-12',
  },

  OtherUserAvatarContainer: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': 'sm-3',
    'ipad:landscape': 'md-2 px-0',
    desktop: 'col-lg-3 col-md-4 col-sm-12 px-0',
  },

  OtherUserInfoContainer: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': 'sm-9 ',
    'ipad:landscape': 'md-10',
    desktop: 'col-lg-9 col-md-8 col-sm-12',
  },

  TimelineRightSidebar: {
    mobile: 'sm-12 px-0',
    'ipad:landscape': 'lg-4 md-4',
    desktop: 'col-md-4 col-12',
  },

  TimelineRightSidebarCard: {
    mobile: 'sm-12 px-0 mb-3',
    'ipad:portrait': '6 mb-3 ipad-port-card-100',
    'ipad:landscape': '12 mb-3',
    desktop: 'col-xl-12 col-lg-12 col-md-6 col-sm-12 mb-3',
  },

  Timeline: {
    mobile: '12 px-0 user-right-section',
    'ipad:portrait': '12 px-0',
    'ipad:landscape': 'lg-8',
    desktop: 'col-md-8 col-12',
  },
};

const SPACE_CLV_CASE_COMPONENT_MAPPER = {
  PatientInfo: {
    mobile: '12 px-0',
    'ipad:portrait': '12',
    'ipad:landscape': '12 ps-0',
    desktop: 'col-xl-6 col-lg-12 col-md-12 col-7 ps-0',
  },
};

const FOLDER_INDEX_COMPONENT_MAPPER = {
  FolderList: {
    'mobile:portrait': '12 mb-2',
    'ipad:portrait': '6 col-lg-4 mb-4',
    'ipad:landscape': '4 mb-4',
    desktop: 'col-12 col-md-6 col-lg-4 col-xxl-3 mb-4',
  },
};

const FOLDER_DETAIL_COMPONENT_MAPPER = {
  BigCard: {
    mobile: '12 mb-3 space-card-col',
    'ipad:portrait': '3 mb-3 space-card-col',
    'ipad:landscape': '3 mb-3 space-card-col',
    desktop: 'col-2-20 mb-3 space-card-col',
  },

  Thumbnail: {
    mobile: 'thumb mb-3 space-card-col',
    'ipad:portrait': 'thumb col-2-20 mb-3 space-card-col',
    'ipad:landscape': 'thumb mb-3 space-card-col',
    desktop: 'col-thumb mb-3 space-card-col',
  },

  ExtraSmall: {
    mobile: '12 mb-3 mb-cards',
    'ipad:portrait': '6 mb-3',
    'ipad:portrait:pro': '4 mb-3',
    'ipad:landscape': '4 mb-3',
    desktop: 'col-3  mb-3',
  },

  TableView: {
    mobile: '12  mb-1 table-list',
    'ipad:portrait': '12  mb-1 table-list',
    'ipad:landscape': '12  mb-1 table-list',
    desktop: 'col-12 mb-1 table-list',
  },
};

const PATIENT_MODAL_COMPONENT_MAPPER = {
  Perosn: {
    mobile: '12 mb-3',
    'ipad:portrait': '6 mb-3',
    'ipad:landscape': '6 mb-3',
    desktop: 'col-md-3 mb-3',
  },
};

const ATTACHMENT_MODAL_COMPONENT_MAPPER = {
  Files: {
    mobile: '12 mb-4',
    'ipad:portrait:mini': '6 mb-4',
    'ipad:portrait:pro': '4 mb-4',
    'ipad:landscape': '4 mb-4',
    desktop: 'col-md-4 mb-4',
  },
};

const RESET_PASSWORD_COMPONENT_MAPPER = {
  ImgCol: {
    mobile: '12 mb-4',
    'ipad:portrait': '6',
    'ipad:portrait:pro': '6',
    'ipad:landscape:pro': '7',
    'ipad:landscape': '6',
    desktop: 'col-6-60',
  },

  FormCol: {
    mobile: '12',
    'ipad:portrait': '6',
    'ipad:portrait:pro': '6',
    'ipad:landscape:pro': '5',
    'ipad:landscape': '6 mb-4',
    desktop: 'col-4-40 mb-4',
  },
};

const THREE_COL_COMPONENT_MAPPER = {
  LeftSidebar: {
    mobile: '12 px-0',
    'ipad:portrait': '3 ps-0 left-wrap is-closed',
    'ipad:landscape': '2 ps-0 left-wrap is-closed',
    desktop: 'col-os-2 ps-0 left-wrap is-closed',
  },

  RightSidebar: {
    mobile: 'sm-12 px-0',
    'ipad:portrait': '12',
    'ipad:landscape': 'lg-4 col-pl-8',
    desktop: 'col-os-2 pe-0 right-wrap',
  },

  MiddleBar: {
    'mobile:portrait': '12 px-0',
    'mobile:landscape': '12',
    'ipad:portrait': '12 position-unset pl-120',
    'ipad:landscape': 'lg-12 position-unset col-pr-8 pl-120',
    desktopMd: 'col-os-12 pl-120',
    desktopHd: 'col-os-6 position-unset xl-middle-bar pt-3',
    desktop: 'col-os-6 position-unset xl-middle-bar pt-3 ',
  },

  RightWithOutMiddleBar: {
    'mobile:portrait': '12 px-0',
    'mobile:landscape': '12',
    'ipad:portrait': '12 position-unset',
    'ipad:landscape': 'lg-12 position-unset col-pr-8',
    desktopMd: 'col-os-12 pl-120',
    desktop: 'col-os-8 position-unset pr-32 pt-3',
  },
};

const CARE_SPACE_DETAIL_COMPONENT_MAPPER = {
  BigCard: {
    mobile: '12 mb-3 space-card-col',
    'ipad:portrait': '3 mb-3 space-card-col',
    'ipad:landscape': '3 mb-3 space-card-col',
    desktop: 'col-lg-3 col-md-4 mb-3 space-card-col',
  },

  Thumbnail: {
    mobile: 'thumb mb-3 space-card-col',
    'ipad:portrait': 'thumb col-2-20 mb-3 space-card-col',
    'ipad:landscape': 'thumb mb-3 space-card-col',
    desktop: 'col-thumb mb-3 space-card-col',
  },

  ExtraSmall: {
    mobile: '12 mb-3 mb-cards',
    'ipad:portrait': '6 mb-3',
    'ipad:portrait:pro': '4 mb-3',
    'ipad:landscape': '4 mb-3',
    desktopHd: 'col-xxl-4 col-lg-6 mb-3',
    desktop: 'col-md-4  mb-3 mw-400',
  },

  TableView: {
    mobile: '12  mb-1 table-list space-card-col',
    'ipad:portrait': '12  mb-1 table-list space-card-col',
    'ipad:landscape': '12  mb-1 table-list space-card-col ',
    desktop: 'col-12 mb-1 table-list space-card-col',
  },
  Card: {
    'mobile:portrait': '12 mb-4',
    'ipad:portrait': 'md-6 col-lg-4 mb-4',
    'ipad:landscape:mini': 'md-4 mb-4',
    'ipad:landscape:pro': 'md-4 mb-4',
    desktop: 'col-xxl-3 col-lg-4 col-md-4 col-6 mb-4',
  },
};

const MODAL_COMPONENT_MAPPER = {
  BigCard: {
    mobile: '12 mb-3 space-card-col',
    'ipad:portrait': '3 mb-3 space-card-col',
    'ipad:landscape': '3 mb-3 space-card-col',
    desktop: 'col-2-20 mb-3 space-card-col',
  },

  Thumbnail: {
    mobile: 'thumb mb-3 space-card-col',
    'ipad:portrait': 'thumb col-2-20 mb-3 space-card-col',
    'ipad:landscape': 'thumb mb-3 space-card-col',
    desktop: 'col-thumb mb-3 space-card-col',
  },

  ExtraSmall: {
    mobile: '12 mb-3 mb-cards',
    'ipad:portrait': '6 mb-3',
    'ipad:portrait:pro': '4 mb-3',
    'ipad:landscape': '4 mb-3',
    desktop: 'col-3  mb-3',
  },

  TableView: {
    mobile: '12  mb-1 table-list space-card-col',
    'ipad:portrait': '12  mb-1 table-list space-card-col',
    'ipad:landscape': '12  mb-1 table-list space-card-col ',
    desktop: 'col-12 mb-1 table-list space-card-col',
  },
  Card: {
    'mobile:portrait': '12 mb-4',
    'ipad:portrait': 'md-6 col-lg-4 mb-4',
    'ipad:landscape:mini': 'md-4 mb-4',
    'ipad:landscape:pro': 'md-4 mb-4',
    desktop: 'col-xxl-3 col-lg-4 col-md-4 col-6 mb-4',
  },
};

const INDEX_PAGE_COMPONENT_MAPPER = {
  PulseIndex: PULSE_INDEX_COMPONENT_MAPPER,
  UserProfile: USER_PROFILE_COMPONENT_MAPPER,
  EditBoard: EDIT_BOARD_COMPONENT_MAPPER,
  UserAccountSetting: USER_ACCOUNT_COMPONENT_MAPPER,
  OnBoard: ONBOARDING_COMPONENT_MAPPER,
  LandignPage: LANDING_PAGE_COMPONENT_MAPPER,
  BoardDetail: DETAIL_BOARD_COMPONENT_MAPPER,
  ContractCard: CONTRACT_CARD_COMPONENT_MAPPER,
  DiscussionCard: DISCUSSION_CARD_COMPONENT_MAPPER,
  PulseDiscussionCard: PULSE_DISCUSSION_CARD_COMPONENT_MAPPER,
  FeedCardColumn: FEED_CARD_COLUMN_COMPONENT_MAPPER,
  TimelineSpaceCommentCardColumn:
    TIMELINE_SPACE_COMMENT_CARD_COLUMN_COMPONENT_MAPPER,
  RelatedCaseWrap: RELATED_CASE_COMPONENT_MAPPER,
  RelatedBoardWrap: RELATED_BOARD_COMPONENT_MAPPER,
  RelatedComparisonWrap: RELATED_COMPARISON_COMPONENT_MAPPER,
  SpaceCreate: SPACE_CREATE_INDEX_COMPONENT_MAPPER,
  CaseDetail: CASE_DETAIL_COMPONENT_MAPPER,
  ToolDetail: DETAIL_TOOL_COMPONENT_MAPPER,
  RecentDiscussions: RECENT_DISCUSSION_COMPONENT_MAPPER,
  DetailRightSidebar: DETAIL_RIGHTSIDEBAR_COMPONENT_MAPPER,
  SpacesIndex: SPACE_INDEX_COMPONENT_MAPPER,
  CommentSection: COMMENT_SECTION_COMPONENT_MAPPER,
  CourseCardBuy: COURSE_CARD_BUY_COMPONENT_MAPPER,
  CsTreatmentLink: CASE_TREATMENT_LINK_MAPPER,
  CsAppointmentSummary: APPOINTEMNT_SUMMARY_COMPONENT_MAPPER,
  SpaceCLV: SPACE_CLV_COMPONENT_MAPPER,
  SpaceDetail: SPACE_DETAIL_COMPONENT_MAPPER,
  CardColumn: CARD_GRID_COMPONENT_MAPPER,
  TrendingCardColumn: TRENDING_CARD_COLUMN_COMPONENT_MAPPER,
  CareSpaceColumn: CARE_SPACE_COMPONENT_MAPPER,
  AppointmentModal: APPOINTMENT_MODAL_COMPONENT_MAPPER,
  SpaceClvCase: SPACE_CLV_CASE_COMPONENT_MAPPER,
  FolderDetail: FOLDER_DETAIL_COMPONENT_MAPPER,
  PatientModal: PATIENT_MODAL_COMPONENT_MAPPER,
  FolderIndex: FOLDER_INDEX_COMPONENT_MAPPER,
  AttachmentModal: ATTACHMENT_MODAL_COMPONENT_MAPPER,
  ResetPassword: RESET_PASSWORD_COMPONENT_MAPPER,
  ThreeCol: THREE_COL_COMPONENT_MAPPER,
  NewAttachmentModal: MODAL_COMPONENT_MAPPER,
  RelatedCareSpacemodal: RELATED_CARESPACE_MODAL_COMPONENT_MAPPER,
  CareSpaceDetail: CARE_SPACE_DETAIL_COMPONENT_MAPPER,
};

class OsGrid extends Component {
  getDeviceClassInfoMapper() {
    const [indexIdentifier, componentIdentifier] =
      this.props.identifier.split(':');
    return INDEX_PAGE_COMPONENT_MAPPER[indexIdentifier][componentIdentifier];
  }

  getContentClass() {
    const currentViewInfo = this.getCurrentViewInfo(),
      deviceClassInfoMapper = this.getDeviceClassInfoMapper(),
      device = currentViewInfo.split(':')[0],
      type = keys(deviceClassInfoMapper).find((type) =>
        currentViewInfo.startsWith(type),
      ),
      deviceClass = deviceClassInfoMapper[device]
        ? `col-${deviceClassInfoMapper[device]}`
        : `col-${deviceClassInfoMapper[type]}`;

    if (device === 'desktop' || device === 'desktopMd') {
      return (
        deviceClassInfoMapper['desktopMd'] || deviceClassInfoMapper['desktop']
      );
    } else if (device === 'desktop' || device === 'desktopHd') {
      return (
        deviceClassInfoMapper['desktopHd'] || deviceClassInfoMapper['desktop']
      );
    } else {
      return deviceClass;
    }
  }

  isContentRenderRequired() {
    const currentViewInfo = this.getCurrentViewInfo(),
      deviceClassInfoMapper = this.getDeviceClassInfoMapper();

    return some(keys(deviceClassInfoMapper), (type) =>
      currentViewInfo.startsWith(type),
    );
  }

  getCurrentViewInfo() {
    const { device } = this.props;

    if (device.mobileDevice) {
      return device.landscape ? 'mobile:landscape' : 'mobile:portrait';
    } else if (device.ipad) {
      if (device.landscape) {
        return device.width > 1024
          ? 'ipad:landscape:pro'
          : 'ipad:landscape:mini';
      } else {
        return device.width > 1023 ? 'ipad:portrait:pro' : 'ipad:portrait:mini';
      }
    } else {
      if (device.width > 1250 && device.width < 1440) {
        return 'desktopMd';
      } else if (device.width >= 1440) {
        return 'desktopHd';
      } else {
        return 'desktop';
      }
    }
  }

  renderContent() {
    const props = this.props.id ? { id: this.props.id } : {};
    return (
      <div
        className={`${this.getContentClass()} ${this.props.extraClass || ''}`}
        {...props}>
        {this.props.children}
      </div>
    );
  }

  render() {
    return this.isContentRenderRequired() ? this.renderContent() : null;
  }
}

OsGrid.defaultProps = {
  id: '',
};
OsGrid = connect(({ device }) => ({ device }))(OsGrid);
export default OsGrid;
