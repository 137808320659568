import React, { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { upperFirst } from 'app/utils/osLodash';
import Tippy from '@tippyjs/react';

import OsTable from 'app/components/OsTable';
import OsBtn from 'app/components/OsBtn';
import { timeWithFormat } from 'app/utils/timeHelper';
import { formatCurrency } from 'app/utils/formatters';
import InvoiceDetailsModal from '../Modals/InvoiceDetailsModal';
import './style.scss';
import { INVOICE_STATUS_CONFIG } from 'app/constants/invoiceStatus';
import { useUpdateCareInvoice } from 'app/hooks/careSpaces/useUpdateCareInvoice';

const GET_CARE_INVOICES = queryLoader(
  'app/graphql/queries/Care/Invoices/List.gql',
);

const InvoiceList = ({ spaceId }) => {
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { data, loading } = useQuery(GET_CARE_INVOICES, {
    variables: { careSpaceId: spaceId },
    fetchPolicy: 'cache-and-network',
  });

  const { updateCareInvoice } = useUpdateCareInvoice();

  const statusCounts = useMemo(() => {
    if (!data?.careInvoices) return {};
    return data.careInvoices.reduce((acc, invoice) => {
      const status = invoice.status.toLowerCase();
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});
  }, [data?.careInvoices]);

  useEffect(() => {
    if (!statusCounts[selectedStatus]) {
      setSelectedStatus(null);
    }
  }, [statusCounts, selectedStatus]);

  // Only show status cards if at least 2 statuses have counts > 0
  const shouldShowStatusCards =
    Object.values(statusCounts).filter((count) => count > 0).length >= 2;

  const getFilteredInvoices = () => {
    if (!data?.careInvoices) return [];
    if (!selectedStatus) return data.careInvoices;
    return data.careInvoices.filter(
      (invoice) =>
        invoice.status.toLowerCase() === selectedStatus.toLowerCase(),
    );
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status === selectedStatus ? null : status);
  };

  const handleViewDetails = (invoice) => {
    setSelectedInvoice(invoice.id);
  };

  const handleMarkAsPaid = (invoice) => {
    updateCareInvoice({ invoiceId: invoice.id, markPaid: true });
  };

  const handleShareWithPatient = (invoice) => {
    updateCareInvoice({ invoiceId: invoice.id, notifyPatient: true });
  };

  const renderActionButton = (options = {}) => {
    const { tooltip, buttonKey, buttonText, icon, isActive, onClick } = options;
    return (
      <Tippy
        placement={'bottom'}
        content={tooltip}
        delay={20}
        animation={'fade'}>
        <div>
          <OsBtn
            key={buttonKey}
            name='BtnIcon'
            text={buttonText}
            leftIcon={true}
            icon={icon}
            extraClass={isActive ? 'active' : ''}
            onClick={onClick}
          />
        </div>
      </Tippy>
    );
  };

  const columns = [
    {
      title: 'Invoice Date',
      key: 'invoiceDate',
      render: (record) => timeWithFormat(record.date, 'MMM DD, YYYY'),
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      render: (record) => timeWithFormat(record.due_date, 'MMM DD, YYYY'),
    },
    {
      title: 'Invoice Number',
      key: 'invoiceNumber',
      render: (record) => record.number,
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (record) => formatCurrency(record.amount),
    },
    {
      title: 'Contract',
      key: 'contract',
      render: (record) => record.contract?.name || '-',
    },
    {
      title: 'Status',
      key: 'status',
      render: (record) => (
        <span className={`status-badge ${record.status.toLowerCase()}`}>
          {upperFirst(record.status)}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <div className='d-flex gap-2 action-buttons'>
          {renderActionButton({
            buttonKey: 'view-details',
            icon: 'passwordShow',
            tooltip: 'View Details',
            onClick: () => handleViewDetails(record),
          })}
          {record.status.toLowerCase() !== 'paid' &&
            renderActionButton({
              buttonKey: 'mark-as-paid',
              icon: 'checkmark-round',
              tooltip: 'Mark as Paid',
              onClick: () => handleMarkAsPaid(record),
            })}
          {!record.shared_with_patient &&
            renderActionButton({
              buttonKey: 'share-with-patient',
              icon: 'send',
              tooltip: 'Share with Patient',
              onClick: () => handleShareWithPatient(record),
            })}
        </div>
      ),
    },
  ];

  const getRowStyle = (record) => {
    const status = record.status.toLowerCase();
    return { backgroundColor: INVOICE_STATUS_CONFIG[status]?.bgColor };
  };

  return (
    <div className='invoice-list'>
      {shouldShowStatusCards && (
        <div className='status-summary mb-4'>
          {Object.entries(statusCounts).map(([status, count]) => {
            if (count === 0) return null;
            const config = INVOICE_STATUS_CONFIG[status];
            if (!config) return null;

            return (
              <div
                key={status}
                className={`status-card ${status} ${
                  selectedStatus === status ? 'selected' : ''
                }`}
                onClick={() => handleStatusClick(status)}
                style={{
                  backgroundColor: config.bgColor,
                  color: config.textColor,
                  cursor: 'pointer',
                }}>
                <div className='status-title'>{config.label}</div>
                <div className='status-count'>{count}</div>
              </div>
            );
          })}
        </div>
      )}

      <OsTable
        columns={columns}
        data={getFilteredInvoices()}
        loading={loading}
        getRowStyle={getRowStyle}
      />

      {selectedInvoice && (
        <InvoiceDetailsModal
          invoiceId={selectedInvoice}
          onClose={() => setSelectedInvoice(null)}
        />
      )}
    </div>
  );
};

export default InvoiceList;
