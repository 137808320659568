import React, { useContext } from 'react';
import { getRGBValue } from 'app/utils/taskHelper';
import {
  firstDayOfGivenDateWeek,
  getDateFromStringWithGivenFormat,
  isDateInThisWeek,
  lastDayOfGivenDateWeek,
} from 'app/utils/timeHelper';
import useClinicSubscribedAccess from 'app/hooks/useClinicSubscribedAccess';

import calendar from 'app/images/task-manager/calendar.svg';
import backArrow from 'app/images/task-manager/backArrow.svg';
import forwardArrow from 'app/images/task-manager/forwardArrow.svg';
import activeBoardIcon from 'app/images/task-manager/activeBoardIcon.svg';
import boardIcon from 'app/images/task-manager/boardIcon.svg';
import activeListIcon from 'app/images/task-manager/activeListIcon.svg';
import listIcon from 'app/images/task-manager/listIcon.svg';
import crossCircle from 'app/images/task-manager/crossCircle.svg';
import { TaskContext } from 'app/context/TaskContext';
import Avatar from 'app/components/Shared/Avatar';
import { avatarAttributes } from 'app/utils/entitiesHelper';
import {
  BOARD,
  HIDE_DATE_BAR,
  HIDE_VIEW_BAR,
  LIST,
  STATUS,
  UNIFIED,
  UNIFIED_BOARD,
  UNIFIED_LIST,
} from 'app/components/Task/taskConstant';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { useSelector } from 'react-redux';
import OsBtn from 'app/components/OsBtn';
import { useNavigate } from 'react-router';
import RenderSearchBar from 'app/components/Task/RenderSearchBar';
import { PaywallContext } from 'app/context/PaywallContext';
import BootstrapDropdown from 'app/components/Shared/BootstrapDropdown/BootstrapDropdown';
import { hexToRgb } from 'app/utils/colorHelper';

const TaskHeader = ({ toggleTaskOpen = () => {}, allTaskOpen = false }) => {
  const {
    heading,
    currentView,
    personFilter,
    showWidget,
    user,
    date,
    allUserOptions,
    isUserView,
    labelsData,
    setShowWidget,
    textQuery,
    openNewTaskModal,
    changeInputSearchRoute,
    backToToday,
    changeWeekHandler,
    changeLabel,
    query,
    changeUserRoute,
    changeView,
    showSearchBar,
    setShowSearchBar,
    labelGroup,
    handleLableGroupChange,
    initializeDemoHook,
  } = useContext(TaskContext);
  const { setOpenPaywall, shouldOpenPaywall } = useContext(PaywallContext);

  const device = useSelector((state) => state.device);
  const workspace = useSelector((state) => state.workspace);

  const navigate = useNavigate();
  const { openSubscriptionModalIfNotSubscribed } = useClinicSubscribedAccess();
  const isKanbanBoard = currentView === BOARD || currentView === UNIFIED_BOARD;

  const onClickHandler = () => {
    if (shouldOpenPaywall()) {
      setOpenPaywall(true);
    } else {
      openSubscriptionModalIfNotSubscribed(openNewTaskModal);
    }
  };

  return (
    <div className={`taskManagerHeader ${device.mobile ? 'mobile-bar' : ''}`}>
      <div className={`team-and-search-bar`}>
        <div className='board-view-header'>
          <div className='back-and-heading'>
            <span onClick={() => navigate(-1)}>
              <OrthoIcon name='chevron-left' />
            </span>
            <div className='teamMembersDropdown'>{heading}</div>
          </div>
          {personFilter && (
            <div className='member'>
              <Avatar
                className='avatar avatar-16'
                {...avatarAttributes(personFilter)}
              />
              <span className='memberName'>{personFilter.full_name}</span>
              <img
                src={crossCircle}
                alt='crossCircle'
                width='16'
                height='16'
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  changeUserRoute({
                    value: 'all_tasks',
                    label: 'All Tasks',
                    name: 'all_tasks',
                  })
                }
              />
            </div>
          )}
          {textQuery &&
            textQuery.map((label) => {
              return (
                <div
                  key={label.id}
                  className='labelMisc'
                  style={{
                    backgroundColor: `${getRGBValue(label.color)}`,
                  }}>
                  {label.displayName}
                  <img
                    src={crossCircle}
                    alt='crossCircle'
                    width='16'
                    height='16'
                    onClick={() =>
                      changeLabel({ label: label, addLabel: false })
                    }
                  />
                </div>
              );
            })}
        </div>
        <div className='taskManagerButtonContainer'>
          {!device.mobile ? (
            <RenderSearchBar
              showWidget={showWidget}
              setShowWidget={setShowWidget}
              changeInputSearchRoute={changeInputSearchRoute}
              query={query}
              labelsData={labelsData}
              allUserOptions={allUserOptions}
              user={user}
              changeLabel={changeLabel}
              changeUserRoute={changeUserRoute}
              currentView={currentView}
            />
          ) : (
            !showSearchBar && (
              <OsBtn
                text=''
                name='BtnIcon'
                icon='search'
                extraClass='px-8 web-view-btn'
                onClick={() => {
                  setShowSearchBar(true);
                }}
              />
            )
          )}

          {showSearchBar && (
            <RenderSearchBar
              showWidget={showWidget}
              setShowWidget={setShowWidget}
              changeInputSearchRoute={changeInputSearchRoute}
              query={query}
              labelsData={labelsData}
              allUserOptions={allUserOptions}
              user={user}
              changeLabel={changeLabel}
              changeUserRoute={changeUserRoute}
              currentView={currentView}
              setShowSearchBar={setShowSearchBar}
              autoFocus={device.mobile}
            />
          )}
          {!showSearchBar && (
            <button onClick={onClickHandler} className='openTaskBtn'>
              <span className='openTaskBtnText'>NEW TASK</span>
            </button>
          )}
        </div>
      </div>
      <div className='date-and-view'>
        <div className='label-dropdown-container'>
          {initializeDemoHook.view === STATUS && (
            <>
              <BootstrapDropdown
                async={false}
                title={
                  labelGroup === null ? (
                    'SELECT A LABEL GROUP'
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        gap: '8px',
                        alignItems: 'center',
                      }}>
                      <span
                        style={{
                          backgroundColor: `${hexToRgb(labelGroup.color)}`,
                          height: '8px',
                          width: '8px',
                          borderRadius: '50%',
                        }}></span>
                      <span className='result-text-data'>
                        {labelGroup.name}
                      </span>
                    </div>
                  )
                }
                emptyState={
                  <div
                    style={{
                      padding: '4px 8px',
                      height: 'fit-content',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <a
                      href={`/${workspace.identifier}/settings/labels`}
                      style={{
                        textAlign: 'left',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                      }}>
                      To group the tasks, click here to create a label group
                      with sub-options.
                    </a>
                  </div>
                }
                inputFieldRequired={false}
                itemKey={'name'}
                onClick={(e, label) => {
                  handleLableGroupChange(label);
                }}
                items={labelsData.filter((l) => l.sub_labels.length > 0)}
              />
              {labelGroup !== null && (
                <span
                  className='clear-btn'
                  onClick={() => handleLableGroupChange(null)}>
                  <OrthoIcon name={'close'} />
                  CLEAR
                </span>
              )}
            </>
          )}
          <div className='date-container d-flex'>
            {!isUserView &&
              currentView !== UNIFIED &&
              !HIDE_DATE_BAR.includes(heading.split(' • ')[1]) && (
                <div className='date-section'>
                  {date !== null &&
                  date !== undefined &&
                  isDateInThisWeek(date) ? (
                    <div className='date'>
                      <img
                        src={backArrow}
                        alt='backArrow'
                        width='16'
                        height='16'
                        style={{ marginRight: '8px', cursor: 'pointer' }}
                        onClick={() => changeWeekHandler('prev')}
                      />
                      <span className='thisWeekText'>This week</span>
                      <img
                        src={forwardArrow}
                        alt='forwardArrow'
                        width='16'
                        height='16'
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => changeWeekHandler('next')}
                      />
                    </div>
                  ) : (
                    <div className='date'>
                      <div
                        className='backToToDayButton'
                        onClick={() => backToToday()}>
                        <img
                          src={calendar}
                          alt='calendar'
                          width='16'
                          height='16'
                        />
                        <span className='backToToday'>BACK TO TODAY</span>
                      </div>
                      <img
                        src={backArrow}
                        alt='backArrow'
                        width='16'
                        height='16'
                        style={{ marginRight: '8px', cursor: 'pointer' }}
                        onClick={() => changeWeekHandler('prev')}
                      />
                      <span>
                        {getDateFromStringWithGivenFormat(
                          firstDayOfGivenDateWeek(date),
                          'MMMM DD',
                        )}{' '}
                        -{' '}
                      </span>
                      <span>
                        {getDateFromStringWithGivenFormat(
                          lastDayOfGivenDateWeek(date),
                          'MMMM DD, YYYY',
                        )}
                      </span>
                      <img
                        src={forwardArrow}
                        alt='forwardArrow'
                        width='16'
                        height='16'
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => changeWeekHandler('next')}
                      />
                    </div>
                  )}
                </div>
              )}
            {isKanbanBoard &&
              !HIDE_VIEW_BAR.includes(heading.split(' • ')[1]) && (
                <OsBtn
                  name='BtnTertiary'
                  icon={allTaskOpen ? 'chevron-caret-up' : 'chevron-caret-down'}
                  text={allTaskOpen ? 'Collapse All' : 'Expand All'}
                  onClick={toggleTaskOpen}
                  extraClass={
                    initializeDemoHook.view === STATUS
                      ? 'status-layout expand-btn'
                      : 'expand-btn'
                  }
                  iconDefaultClass='ps-1 pe-0 right-icon'
                  leftIcon={false}
                />
              )}
          </div>
        </div>
        <div className='view-container'>
          {!isUserView && !HIDE_VIEW_BAR.includes(heading.split(' • ')[1]) && (
            <div className='view-section'>
              <button
                onClick={() => changeView(LIST)}
                className='listBtn'
                style={
                  currentView === LIST || currentView === UNIFIED_LIST
                    ? { backgroundColor: '#354554' }
                    : { backgroundColor: '#fff' }
                }>
                {currentView === LIST || currentView === UNIFIED_LIST ? (
                  <img
                    src={activeListIcon}
                    alt={'activeListIcon'}
                    width='20'
                    height='20'
                  />
                ) : (
                  <img src={listIcon} alt={'listIcon'} width='20' height='20' />
                )}
              </button>

              <button
                onClick={() => changeView(BOARD)}
                className='listBtn'
                style={
                  isKanbanBoard
                    ? { backgroundColor: '#354554' }
                    : { backgroundColor: '#fff' }
                }>
                {isKanbanBoard ? (
                  <img
                    src={activeBoardIcon}
                    alt={'activeBoardIcon'}
                    width='20'
                    height='20'
                  />
                ) : (
                  <img
                    src={boardIcon}
                    alt={'boardIcon'}
                    width='20'
                    height='20'
                  />
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskHeader;
