import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

const UPDATE_CARE_INVOICE = queryLoader(
  'app/graphql/mutations/CareInvoice/Update.gql',
);

export const useUpdateCareInvoice = () => {
  const [updateCareInvoice, { loading }] = useMutation(UPDATE_CARE_INVOICE);

  const updateCareInvoiceMutation = useCallback(
    (variables) => {
      updateCareInvoice({ variables });
    },
    [updateCareInvoice],
  );

  return {
    updateCareInvoice: updateCareInvoiceMutation,
    updating: loading,
  };
};
