import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { currentTimeWithUserTimeZone } from 'app/utils/timeHelper';
import { BaseTaskContext } from 'app/context/TaskContext';
import TaskManager from 'app/components/Task/index';
import { getWorkspace } from 'app/selectors/workspace';
import TaskFeedListing from 'app/components/Task/TaskFeedListing';
import PaginatedTaskFeedListing from 'app/components/Task/PaginatedTaskFeedListing';
import TaskPreview from 'app/components/TaskPreviewModal/TaskPreview';
import { useContext } from 'react';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';

const BaseTaskManger = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const workspace = useSelector(getWorkspace);
  const { all_members, task_labels } = useContext(WorkspaceContext);
  const teamMembersData = { workspace: { all_members } };

  return (
    <BaseTaskContext.Provider
      value={{
        teamMembersData: teamMembersData,
        currentUser: currentUser,
        labelsData: task_labels,
        workspace: workspace,
      }}>
      <Routes>
        <Route path=':taskId' element={<TaskPreview isModal={true} />} />
        <Route
          path={'/'}
          element={
            <TaskManager
              user={{
                value: currentUser.graph.id,
                label: 'My Tasks',
                name: currentUser.graph.name,
              }}
              date={currentTimeWithUserTimeZone()}
              heading={'My Tasks • Weekly'}
            />
          }
        />
        <Route path={'me'}>
          <Route
            path={'activity'}
            element={<TaskFeedListing activity={'me'} />}
          />
          <Route
            path={'inbox'}
            element={
              <TaskManager
                user={{
                  value: 'inbox',
                  label: 'Task Inbox',
                  name: 'inbox',
                }}
                date={currentTimeWithUserTimeZone()}
                isUserView={true}
                heading={'My Tasks • Inbox'}
                view={'list'}
              />
            }
          />
          <Route
            path={'overdue'}
            element={
              <TaskManager
                user={{
                  value: currentUser.graph.id,
                  label: 'Overdue',
                  name: currentUser.graph.name,
                }}
                date={currentTimeWithUserTimeZone()}
                isOverdueView={true}
                heading={'My Tasks • Overdue'}
                view={'list'}
              />
            }
          />
          <Route
            path={'upcoming'}
            element={
              <TaskManager
                user={{
                  value: currentUser.graph.id,
                  label: 'Upcoming',
                  name: currentUser.graph.name,
                }}
                date={currentTimeWithUserTimeZone()}
                heading={'My Tasks • Upcoming'}
                isUpcomingView={true}
                view={'list'}
              />
            }
          />
          <Route path={'status'}>
            <Route
              path={'list'}
              element={
                <TaskManager
                  user={{
                    value: currentUser.graph.id,
                    label: 'My Tasks',
                    name: currentUser.graph.name,
                  }}
                  date={currentTimeWithUserTimeZone()}
                  heading={'My Tasks • By Status'}
                  view={'status'}
                />
              }
            />
            <Route
              path={'board'}
              element={
                <TaskManager
                  user={{
                    value: currentUser.graph.id,
                    label: 'My Tasks',
                    name: currentUser.graph.name,
                  }}
                  date={currentTimeWithUserTimeZone()}
                  heading={'My Tasks • By Status'}
                  view={'status'}
                />
              }
            />
          </Route>

          <Route path={'today'}>
            <Route
              path={'list'}
              element={
                <TaskManager
                  user={{
                    value: currentUser.graph.id,
                    label: 'My Tasks',
                    name: currentUser.graph.name,
                  }}
                  date={currentTimeWithUserTimeZone()}
                  heading={'My Tasks • Weekly'}
                  view={'list'}
                />
              }
            />
            <Route
              path={'board'}
              element={
                <TaskManager
                  user={{
                    value: currentUser.graph.id,
                    label: 'My Tasks',
                    name: currentUser.graph.name,
                  }}
                  date={currentTimeWithUserTimeZone()}
                  heading={'My Tasks • Weekly'}
                  view={'board'}
                />
              }
            />
          </Route>
        </Route>

        <Route path={'me/:date'}>
          <Route
            path={'list'}
            element={
              <TaskManager
                user={{
                  value: currentUser.graph.id,
                  label: 'My Tasks',
                  name: currentUser.graph.name,
                }}
                heading={'My Tasks • Weekly'}
                view={'list'}
              />
            }
          />

          <Route
            path={'board'}
            element={
              <TaskManager
                user={{
                  value: currentUser.graph.id,
                  label: 'My Tasks',
                  name: currentUser.graph.name,
                }}
                heading={'My Tasks • Weekly'}
                view={'board'}
              />
            }
          />
        </Route>

        <Route path={'team'}>
          <Route
            path={'activity'}
            element={<TaskFeedListing activity={'team'} />}
          />
          <Route
            path={'overdue'}
            element={
              <PaginatedTaskFeedListing activity={'team'} type={'overdue'} />
            }
          />
          <Route
            path={'delegated'}
            element={
              <TaskManager
                user={{
                  value: 'delegated',
                  label: 'Assigned By You',
                  name: 'Delegated',
                }}
                date={currentTimeWithUserTimeZone()}
                isUserView={true}
                heading={'Team Tasks • Assigned By Me'}
                view={'list'}
              />
            }
          />
          <Route
            path={'upcoming'}
            element={
              <TaskManager
                user={{
                  value: 'all_tasks',
                  label: 'All Tasks',
                  name: 'All Tasks',
                }}
                date={currentTimeWithUserTimeZone()}
                heading={'Team Tasks • Upcoming'}
                isUpcomingView={true}
                view={'list'}
              />
            }
          />
          <Route path={'status'}>
            <Route
              path={'list'}
              element={
                <TaskManager
                  user={{
                    value: 'all_tasks',
                    label: 'All Tasks',
                    name: 'All Tasks',
                  }}
                  date={currentTimeWithUserTimeZone()}
                  heading={'Team Tasks • By Status'}
                  view={'status'}
                />
              }
            />
            <Route
              path={'board'}
              element={
                <TaskManager
                  user={{
                    value: 'all_tasks',
                    label: 'All Tasks',
                    name: 'All Tasks',
                  }}
                  date={currentTimeWithUserTimeZone()}
                  heading={'Team Tasks • By Status'}
                  view={'status'}
                />
              }
            />
          </Route>

          <Route path={'today'}>
            <Route
              path={'list'}
              element={
                <TaskManager
                  user={{
                    value: 'all_tasks',
                    label: 'All Tasks',
                    name: 'All Tasks',
                  }}
                  date={currentTimeWithUserTimeZone()}
                  heading={'Team Tasks • Weekly'}
                  view={'list'}
                />
              }
            />
            <Route
              path={'board'}
              element={
                <TaskManager
                  user={{
                    value: 'all_tasks',
                    label: 'All Tasks',
                    name: 'All Tasks',
                  }}
                  date={currentTimeWithUserTimeZone()}
                  heading={'Team Tasks • Weekly'}
                  view={'board'}
                />
              }
            />
          </Route>
        </Route>

        <Route path={'team/:date'}>
          <Route
            path={'list'}
            element={
              <TaskManager
                user={{
                  value: 'all_tasks',
                  label: 'All Tasks',
                  name: 'All Tasks',
                }}
                heading={'Team Tasks • Weekly'}
                view={'list'}
              />
            }
          />

          <Route
            path={'board'}
            element={
              <TaskManager
                user={{
                  value: 'all_tasks',
                  label: 'All Tasks',
                  name: 'All Tasks',
                }}
                heading={'Team Tasks • Weekly'}
                view={'board'}
              />
            }
          />
        </Route>
      </Routes>
    </BaseTaskContext.Provider>
  );
};

export default BaseTaskManger;
