export const INVOICE_STATUS_CONFIG = {
  paid: {
    label: 'Paid',
    bgColor: '#e8f5e9',
    textColor: '#2e7d32',
  },
  unpaid: {
    label: 'Unpaid',
    bgColor: '#fff3e0',
    textColor: '#ef6c00',
  },
  overdue: {
    label: 'Overdue',
    bgColor: '#ffebee',
    textColor: '#c62828',
  },
};
