import { useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

const PATIENT_SUGGESTION_LISTING = queryLoader(
  'app/graphql/queries/PatientSuggestionListing.gql',
);

const useFetechAllPatients = () => {
  const [query, setQuery] = useState('');
  const { data, loading: patientsLoading } = useQuery(
    PATIENT_SUGGESTION_LISTING,
    {
      variables: {
        page: 0,
        perPage: 10,
        type: 'OnlyPatientsOfClinic',
        text_query: query.length > 3 ? query : '',
        sortQuery: '',
        searchQuery: '',
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  return {
    patients: data?.records?.results || [],
    patientsLoading,
    setQuery,
  };
};

export default useFetechAllPatients;
