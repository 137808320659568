import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from 'react-loaders';
import isEqual from 'lodash/isEqual';
import flatten from 'lodash/flatten';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { loader as queryLoader } from 'graphql.macro';
import { getRGBValue } from 'app/utils/taskHelper';
import crossCircle from 'app/images/task-manager/crossCircle.svg';
import { avatarAttributes } from 'app/utils/entitiesHelper';
import Avatar from '../Shared/Avatar';

import 'app/components/Task/task-feed-view.scss';
import { useFetchPaginatedRecords } from 'app/hooks/useFetchPaginatedRecords';
import TaskPreview from 'app/components/TaskPreviewModal/TaskPreview';
import Pagination from 'app/components/Shared/Pagination';

const TASK_FEED_LISTING = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);

const PaginatedTaskFeedListing = ({ activity, user, type }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { all_members, task_labels } = useContext(WorkspaceContext);
  const device = useSelector((state) => state.device);

  const page = searchParams.get('page') || 1;
  const userId = searchParams.get('user');
  const labelIds = useMemo(
    () => searchParams.get('label')?.split(',') || [],
    [searchParams],
  );
  const [currentPage, setCurrentPage] = useState(page);
  const [currentUserId, setCurrentUserId] = useState(userId);
  const [currentLabelIds, setCurrentLabelIds] = useState(labelIds);

  const { fetchData, records, loading, total } = useFetchPaginatedRecords(
    TASK_FEED_LISTING,
    {
      page: 1,
      perPage: 20,
      type: 'OverDueTasks',
      sortQuery: 'due_date_desc',
    },
  );

  const personFilter = useMemo(() => {
    if (currentUserId)
      return all_members?.find((member) => member.id === currentUserId);
    return null;
  }, [currentUserId, all_members]);

  const selectedLabels = useMemo(() => {
    if (currentLabelIds.length > 0)
      return flatten(
        task_labels.map((label) => [
          label,
          ...label.sub_labels.map((subLabel) => ({
            ...subLabel,
            color: label.color,
          })),
        ]),
      )?.filter((label) => currentLabelIds.includes(String(label.id)));
    return [];
  }, [currentLabelIds, task_labels]);

  const onPageChange = (page) => {
    searchParams.set('page', page);
    setCurrentPage(page);
    setSearchParams(searchParams);
  };

  const changeLabel = (label) => {
    const existingLabels = searchParams.get('label')?.split(',');
    const newLabels = existingLabels?.filter((l) => l !== label.id);
    if (newLabels?.length) {
      searchParams.set('label', newLabels);
    } else {
      searchParams.delete('label');
    }
    setCurrentLabelIds(newLabels);
    setSearchParams(searchParams);
  };

  const deleteUser = (user) => {
    searchParams.delete('user');
    setSearchParams(searchParams);
    setCurrentUserId(null);
  };

  useEffect(() => {
    if (userId !== currentUserId) setCurrentUserId(userId);
  }, [userId, currentUserId]);

  useEffect(() => {
    if (!isEqual(labelIds.sort(), currentLabelIds.sort()))
      setCurrentLabelIds(labelIds);
  }, [labelIds, currentLabelIds]);

  useEffect(() => {
    fetchData({
      page: +currentPage - 1,
      perPage: 10,
      id_query: currentUserId || '',
      id_type: 'User',
      additional_filters: JSON.stringify({
        label_ids: currentLabelIds,
      }),
    });
  }, [currentPage, currentUserId, currentLabelIds]);

  const renderTask = (task, activities) => {
    return (
      <div id={`task-id-${task.id}`} key={task.id}>
        <div className='task-wrapper'>
          <TaskPreview
            task={task}
            hideCloseBtn={true}
            showSeeMoreButton={true}
            loaderNotRequired={true}
            nextPageLoadRequired={false}
            activities={activities}
          />
        </div>
      </div>
    );
  };

  const renderLabelFilters = (label) => {
    return (
      <div
        key={label.id}
        className='labelMisc'
        style={{
          backgroundColor: `${getRGBValue(label.color)}`,
        }}>
        {label.displayName}
        <img
          src={crossCircle}
          alt='crossCircle'
          width='16'
          height='16'
          onClick={() => changeLabel(label, false)}
        />
      </div>
    );
  };

  const renderData = () => {
    if (loading) return <Loader type='ball-triangle-path' active />;
    return records.map((record) => renderTask(record, []));
  };

  return (
    <div className='taskManagerWrapper'>
      <div className={`taskManagerHeader ${device.mobile ? 'mobile-bar' : ''}`}>
        <div className={`team-and-search-bar`}>
          <div className={`board-view-header`}>
            <div className='back-and-heading'>
              <div className='teamMembersDropdown'>
                Team Tasks • Overdue Tasks
              </div>
            </div>
            {personFilter && (
              <div className='member'>
                <Avatar
                  className='avatar avatar-16'
                  {...avatarAttributes(personFilter)}
                />
                <span className='memberName'>{personFilter.full_name}</span>
                <img
                  src={crossCircle}
                  alt='crossCircle'
                  width='16'
                  height='16'
                  style={{ cursor: 'pointer' }}
                  onClick={deleteUser}
                />
              </div>
            )}
            {selectedLabels && selectedLabels.map(renderLabelFilters)}
          </div>
        </div>
      </div>
      <div className='task-list'>{renderData()}</div>
      {!loading && records.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalItems={total}
          perPage={10}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default PaginatedTaskFeedListing;
