import React, { useState, useRef } from 'react';
import RecordsView from 'app/components/RecordsView';

const CareInvoiceFeedListing = ({ entity }) => {
  const [activityCount, setActivityCount] = useState(null);
  const parentContainerRef = useRef(null);

  return (
    <div ref={parentContainerRef}>
      <RecordsView
        type='CareInvoiceActivities'
        queryType='COMMENTS_ACTIVITY_LISTING'
        perPage={10}
        idQuery={entity.id.toString()}
        infiniteScroll={true}
        entity={entity}
        updateActivityCount={setActivityCount}
        key={entity.id}
        isReverse={true}
        threshold={1}
        fetchPolicy='network-only'
        windowNotRequired={true}
        fullViewListing={true}
        parentContainer={parentContainerRef}
      />
    </div>
  );
};

export default CareInvoiceFeedListing;
