import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

import OsBtn from 'app/components/OsBtn';
import { timeWithFormat } from 'app/utils/timeHelper';
import { formatCurrency } from 'app/utils/formatters';
import CareInvoiceFeedListing from 'app/components/CareInvoiceFeedLisitng';
import { INVOICE_STATUS_CONFIG } from 'app/constants/invoiceStatus';
import './style.scss';

const GET_INVOICE_DETAILS = queryLoader(
  'app/graphql/queries/Care/Invoices/Detail.gql',
);

const InvoiceDetailsModal = ({ invoiceId, onClose }) => {
  const [comment, setComment] = useState('');
  const { data, loading } = useQuery(GET_INVOICE_DETAILS, {
    variables: { careInvoiceId: String(invoiceId) },
    fetchPolicy: 'cache-and-network',
  });

  const addComment = () => {
    console.log('addComment', comment);
  };

  const addingComment = false;
  //   const [addComment, { loading: addingComment }] = useMutation(ADD_INVOICE_COMMENT, {
  //     refetchQueries: [{ query: GET_INVOICE_DETAILS, variables: { invoiceId } }],
  //   });

  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!comment.trim()) return;

    try {
      await addComment({
        variables: {
          invoiceId,
          comment: comment.trim(),
        },
      });
      setComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const { invoice } = data;
  const status = invoice.status.toLowerCase();
  const statusStyle = INVOICE_STATUS_CONFIG[status];

  return (
    <Modal show={true} onHide={onClose} centered size='xl'>
      <Modal.Header closeButton className='invoice-header'>
        <Modal.Title className='d-flex align-items-center gap-3 flex-wrap'>
          Invoice Details
          <span
            className='status-pill'
            style={{
              backgroundColor: statusStyle.bgColor,
              color: statusStyle.textColor,
            }}>
            {statusStyle.label}
          </span>
          {invoice.shared_with_patient && (
            <span className='status-pill shared-with-patient'>
              Shared with Patient
            </span>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='invoice-details'>
          <div
            className='invoice-info mb-4'
            style={{
              backgroundColor: statusStyle.bgColor,
              color: statusStyle.textColor,
            }}>
            <div className='row'>
              <div className='col-md-6'>
                <div className='info-item'>
                  <label>Invoice Number:</label>
                  <span>{invoice.number}</span>
                </div>
                <div className='info-item'>
                  <label>Amount:</label>
                  <span>{formatCurrency(invoice.amount)}</span>
                </div>
                <div className='info-item'>
                  <label>Notes:</label>
                  <span>{invoice.notes || '-'}</span>
                </div>
                {invoice.paid_marked_by && (
                  <div className='info-item'>
                    <label>Paid Marked By:</label>
                    <span>{invoice.paid_marked_by?.name}</span>
                  </div>
                )}
              </div>
              <div className='col-md-6'>
                <div className='info-item'>
                  <label>Contract:</label>
                  <span>{invoice.contract?.name}</span>
                </div>
                <div className='info-item'>
                  <label>Invoice Date:</label>
                  <span>{timeWithFormat(invoice.date, 'MMM DD, YYYY')}</span>
                </div>
                <div className='info-item'>
                  <label>Due Date:</label>
                  <span>
                    {timeWithFormat(invoice.due_date, 'MMM DD, YYYY')}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='add-comment mb-3'>
            <Form onSubmit={handleAddComment}>
              <Form.Group>
                <Form.Label>Add Comment</Form.Label>
                <div className='d-flex gap-2'>
                  <Form.Control
                    type='text'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder='Type your comment here...'
                  />
                  <OsBtn
                    text='Add'
                    type='submit'
                    disabled={!comment.trim() || addingComment}
                    extraClass='btn-primary'
                  />
                </div>
              </Form.Group>
            </Form>
          </div>

          <div className='activity-section'>
            <h6 className='mb-3'>Activity History</h6>
            <div className='activity-list'>
              <CareInvoiceFeedListing entity={invoice} />
              {invoice.activities?.map((activity) => (
                <div key={activity.id} className='activity-item'>
                  <div className='activity-header'>
                    <span className='activity-type'>{activity.type}</span>
                    <span className='activity-time'>
                      {timeWithFormat(activity.createdAt, 'MMM DD, YYYY HH:mm')}
                    </span>
                  </div>
                  <div className='activity-content'>{activity.content}</div>
                  <div className='activity-user'>By: {activity.user.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <OsBtn text='Close' onClick={onClose} extraClass='btn-secondary' />
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceDetailsModal;
