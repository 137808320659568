import { useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

const GET_CARE_SPACE_CONTRACTS = queryLoader(
  'app/graphql/queries/Care/ContractListQuery.gql',
);

export const useGetContracts = (careSpaceId) => {
  const { data, loading } = useQuery(GET_CARE_SPACE_CONTRACTS, {
    variables: { careSpaceId },
  });
  return {
    contracts: data?.contracts || [],
    loading,
  };
};
