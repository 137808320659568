import React from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import withAuthenticate from 'app/components/HOC/withAuthenticate';
import withSpaceRestriction from 'app/components/HOC/withSpaceRestriction';

import Icon from 'app/components/Svg';
import NavTabs from './index';
import SimpleNavList from 'app/components/SimpleNavList';
import SpaceBanner from 'app/components/BoardView/SpaceBanner';
import StickyHeader from 'app/components/StickyHeader';
import VerificationProcessNotionLink from 'app/components/Shared/VerificationProcessNotionLink';

import EventTracker from 'app/services/EventTracker';

import { isCareSpace } from 'app/utils/spaceHelper';

import { openInfoModal } from 'app/actions/infoModal';
import { openOnboardingVerificationModals } from 'app/actions/onboardingTips';

import { entityUrl } from 'app/utils/entitiesHelper';
import {
  isGuestUser,
  isGeneralUser,
  userVerificationDataReceived,
} from 'app/utils/userHelper';
import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';
import {
  MEMBER_COUNT_MIN_LIMIT_TO_DISPLAY,
  SPACE_ACTIVITY_FEED_FILTERS,
} from 'app/components/BoardView/constants';
import { tabNotAvailableAsUserNotVerified } from 'app/utils/spaceHelper';
import { isMobileExpandedNavTabEnabled } from 'app/utils/featureHelper';
import {
  contentAccessibleTabs,
  getVisibleTabs,
  isNavNotListRequired,
} from './helper';

class SpaceNavTabs extends NavTabs {
  componentDidMount() {
    addClassToHTML('tab-hide-in-app');
    if (this.selectedFilter() && this.props.device.mobileDevice)
      addClassToHTML('mobile-space-view');
    if (
      tabNotAvailableAsUserNotVerified(this.getEntity(), this.selectedFilter())
    )
      setTimeout(this.showProfileIncompleteModal, 1500);
    this.setURL();
  }

  componentDidUpdate(prevProps) {
    if (!this.selectedFilter() && prevProps.match.params.filter)
      removeClassFromHTML('mobile-space-view');
    if (this.selectedFilter() && !prevProps.match.params.filter)
      addClassToHTML('mobile-space-view');
    if (
      !this.selectedFilter() ||
      prevProps.space.is_author_or_collaborator !==
        this.getEntity().is_author_or_collaborator
    ) {
      this.setURL();
    }
    if (
      prevProps.device.outerWidth.desktopSmall &&
      !this.props.device.desktopSmall
    ) {
      if (
        this.props.match.params.filter !== 'files' &&
        prevProps.match.params.filter !== 'files'
      )
        this.goToSelectedTab('discussions');
    }
  }

  componentWillUnmount() {
    removeClassFromHTML('tab-hide-in-app');
    removeClassFromHTML('mobile-space-view');
  }

  isFilterValid(filter) {
    return contentAccessibleTabs(this.getEntity(), 'nav_tabs')
      .map((tab) => tab.path)
      .includes(filter);
  }

  setURL() {
    let selectedFilter = this.selectedFilter(),
      isUserNotLoggedIn = isGuestUser();

    if (
      (!selectedFilter && !isMobileExpandedNavTabEnabled(this.getEntity())) ||
      (selectedFilter && !this.isFilterValid(selectedFilter))
    ) {
      let redirectPath =
          this.firstAccessibleTab().path +
          (isCareSpace(this.getEntity()) ? '/all' : ''),
        path = `${entityUrl(this.getEntity(), false, {
          skipDomainCheck: true,
          skipWorkspaceIdentifer: isUserNotLoggedIn,
        })}/${redirectPath}`;

      redirectPath &&
        setTimeout(
          () =>
            this.props.navigate(path, {
              replace: true,
              skip: isUserNotLoggedIn,
            }),
          100,
        );
    }
  }

  getUnreadDiscussionsCount() {
    return this.getEntity().unseen_comments_count;
  }

  getDiscussionsCount() {
    let count,
      unreadDiscussionsCount = this.getUnreadDiscussionsCount();
    if (unreadDiscussionsCount) {
      count = unreadDiscussionsCount;
      count = '+' + count;
    } else {
      count = this.getEntity().comments_count;
    }
    return count;
  }

  getScheduleMessageCount() {
    return this.props.space?.schedule_message_count;
  }

  getVisibleTabs() {
    return getVisibleTabs(this.getEntity());
  }

  getFolderSpaceMemebersCount() {
    return this.props.space?.all_members_count;
  }

  getSharedFilesCount() {
    return this.props.space?.nav_tabs?.find(
      (tab) => tab.name === 'Files' || tab.kind === 'content',
    )?.content_count;
  }

  filterCountMapper() {
    let filterCountMapper = {};
    this.getVisibleTabs().forEach((tab) => {
      if (tab.kind === 'discussions') {
        filterCountMapper[tab.path] = this.getDiscussionsCount();
      } else if (tab.kind === 'members') {
        filterCountMapper[tab.path] = this.getFolderSpaceMemebersCount();
      } else if (tab.kind === 'scheduled_posts') {
        filterCountMapper[tab.path] = this.getScheduleMessageCount();
      } else if (tab.kind === 'content') {
        filterCountMapper[tab.path] = this.getSharedFilesCount();
      } else {
        filterCountMapper[tab.path] = tab.content_count;
      }
    });
    return filterCountMapper;
  }

  goToSelectedTab = (path, feedType = null) => {
    if (path === 'activity') {
      if (feedType) path = SPACE_ACTIVITY_FEED_FILTERS.path_mapper[feedType];
    }

    this.props.navigate(
      `${entityUrl(this.getEntity(), false, {
        skipDomainCheck: true,
        skipWorkspaceIdentifer: isGuestUser(),
      })}/${path}`,
      { skip: isGuestUser() },
    );
  };

  showProfileIncompleteModal = () => {
    if (userVerificationDataReceived()) {
      this.props.openInfoModal(
        'general',
        'user_verification_awaited_for_approval',
        {
          contentInterpolations: {
            verification_process_link: <VerificationProcessNotionLink />,
          },
        },
      );
    } else {
      this.props.openInfoModal(
        'general',
        'user_verification_required_for_discussions',
        {
          contentInterpolations: {
            verification_process_link: <VerificationProcessNotionLink />,
          },
          onSuccess: this.props.openOnboardingVerificationModals,
        },
      );
    }
  };

  openRestrictedTabsModal = (filter) => {
    if (tabNotAvailableAsUserNotVerified(this.getEntity(), filter)) {
      this.showProfileIncompleteModal();
    }
  };

  setFilterIfAccessible = (filter, type, feedType = '') => {
    EventTracker.track('Space Action', { tab: type });
    if (
      filter === 'patient_details' ||
      filter === 'care_summary' ||
      filter === 'related_tasks' ||
      filter === 'clinic_details' ||
      filter === 'popular_hashtag' ||
      filter === 'treatment_progress' ||
      filter === 'scheduled_posts' ||
      filter === 'patient_status' ||
      filter === 'invoices'
    ) {
      this.goToSelectedTab(filter);
    } else if (this.isFilterValid(filter)) {
      this.goToSelectedTab(filter, feedType);
    } else {
      if (isGuestUser()) {
        this.props.redirectToDefault();
      } else {
        this.props.openRestrictedModalIfNotAMember(
          this.getEntity(),
          this.openRestrictedTabsModal.bind(this, filter),
        );
      }
    }
  };

  getMemberTab() {
    return this.getVisibleTabs().find((tab) => tab.kind === 'members');
  }

  tabsRoundingCount() {
    if (
      (isGeneralUser() || isGuestUser()) &&
      !this.getEntity().is_author_or_collaborator
    ) {
      let memberTab = this.getMemberTab();
      return memberTab
        ? { tab: [memberTab.path], minValue: MEMBER_COUNT_MIN_LIMIT_TO_DISPLAY }
        : {};
    }
  }

  renderTabs(visibleTabs) {
    const list = visibleTabs.map((tab) => [tab.name, tab.path]),
      searchAndFilterOptions = {
        ...this.props.searchAndFilterOptions,
        searchRequired: this.selectedTab().search_required,
      };

    return (
      <SimpleNavList
        list={list}
        onFilterClick={this.setFilterIfAccessible}
        currentFilter={this.selectedFilter()}
        filterCountMapper={this.filterCountMapper()}
        tabsRoundingCount={this.tabsRoundingCount()}
        searchAndFilterRequired={this.props.searchAndFilterRequired}
        searchAndFilterOptions={searchAndFilterOptions}
        space={this.props.space}
      />
    );
  }

  renderEmptyState() {
    return (
      <div className='text-center no-content-placeholder'>
        <Icon name='information' />
        <div className='no-content-placeholder-text'>
          Nothing has been added to the space yet.
        </div>
      </div>
    );
  }

  render() {
    let visibleTabs = this.getVisibleTabs(),
      isTabsPresent = !!visibleTabs.length && this.firstAccessibleTab().path;

    if (isNavNotListRequired(this.getEntity())) {
      return <div className='container'>{this.props.children}</div>;
    } else {
      return (
        <>
          <StickyHeader
            extraClass={this.props.stickExtraClass}
            isHeadSticky={this.props.isHeadSticky}
            containerClass={'tab-spacing'}>
            {this.props.children}
            {isTabsPresent && this.renderTabs(visibleTabs)}
          </StickyHeader>
          {isTabsPresent && (
            <SpaceBanner
              space={this.getEntity()}
              selectedFilter={this.selectedFilter()}
            />
          )}
          {!isTabsPresent && this.renderEmptyState()}
        </>
      );
    }
  }
}

SpaceNavTabs.defaultProps = {
  searchAndFilterRequired: false,
  searchAndFilterOptions: {},
};

SpaceNavTabs = connect(({ currentUser, device }) => ({ currentUser, device }), {
  openInfoModal,
  openOnboardingVerificationModals,
})(SpaceNavTabs);
SpaceNavTabs = withRouter(SpaceNavTabs);
SpaceNavTabs = withAuthenticate(SpaceNavTabs);
SpaceNavTabs = withSpaceRestriction(SpaceNavTabs);
export default SpaceNavTabs;
