import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import OsBtn from 'app/components/OsBtn';
import ContractModal from 'app/components/Modals/ContractModal';
import InvoiceModal from 'app/components/Modals/CareInvoiceModal';

import { isFeaturePermitted } from 'app/utils/userHelper';
import { CONTRACT_ADDITION_IDENTIFIER } from 'app/components/FeatureFlagging/constants';

const ActionItemsBar = ({
  isInternalNote,
  openTaskModal,
  openMessageSection,
  editorButtonTextRequired,
  editorType,
  isCareSpace,
  entityId,
}) => {
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const renderTippyButton = (config) => {
    const { tooltip, buttonKey, buttonText, icon, isActive, onClick } = config;

    return (
      <Tippy
        placement={'bottom'}
        content={tooltip}
        delay={20}
        animation={'fade'}>
        <div>
          <OsBtn
            key={buttonKey}
            name='BtnIcon'
            text={editorButtonTextRequired ? buttonText : ''}
            leftIcon={true}
            icon={icon}
            extraClass={isActive ? 'active' : ''}
            onClick={onClick}
          />
        </div>
      </Tippy>
    );
  };

  const renderEditorButtons = () => {
    const buttons = [
      {
        tooltip: 'Message your Patient',
        buttonKey: 'message-to-patient',
        buttonText: 'Message to patient',
        icon: 'send',
        isActive: editorType === 'patient',
        onClick: () => openMessageSection('patient'),
      },
      {
        tooltip: 'Internal Note',
        buttonKey: 'add-internal-note',
        buttonText: 'Add Internal Note',
        icon: 'locked',
        isActive: isInternalNote,
        onClick: () => openMessageSection('internalNote'),
      },
      {
        tooltip: 'Create new Task',
        buttonKey: 'add-task-btn',
        buttonText: '',
        icon: 'task',
        onClick: () => openTaskModal(),
      },
      isCareSpace && isFeaturePermitted(CONTRACT_ADDITION_IDENTIFIER)
        ? {
            tooltip: 'Create new Contract',
            buttonKey: 'add-contract-btn',
            buttonText: '',
            icon: 'notes',
            onClick: () => setIsContractModalOpen(true),
          }
        : null,
      isCareSpace && isFeaturePermitted(CONTRACT_ADDITION_IDENTIFIER)
        ? {
            tooltip: 'Create new Invoice',
            buttonKey: 'add-invoice-btn',
            buttonText: '',
            icon: 'payment',
            onClick: () => setIsInvoiceModalOpen(true),
          }
        : null,
    ];

    return buttons.filter(Boolean).map((config) => renderTippyButton(config));
  };

  return (
    <>
      {renderEditorButtons()}
      {isContractModalOpen && (
        <ContractModal
          onClose={() => setIsContractModalOpen(false)}
          spaceId={entityId}
        />
      )}
      {isInvoiceModalOpen && (
        <InvoiceModal
          onClose={() => setIsInvoiceModalOpen(false)}
          spaceId={entityId}
        />
      )}
    </>
  );
};

export default ActionItemsBar;
