export const MY_INBOX_TASKS = '/tasks/me/inbox';
export const MY_WEEKLY_TASKS = '/tasks/me/today/list';
export const MY_WEEKLY_BOARD_TASKS = '/tasks/me/today/board';
export const MY_STATUS_TASKS = '/tasks/me/status/list';
export const MY_OVERDUE_TASKS = '/tasks/me/overdue';
export const MY_UPCOMING_TASKS = '/tasks/me/upcoming';
export const MY_TASK_ACTIVITY = '/tasks/me/activity';

export const ASSIGNED_BY_ME_TASKS = '/tasks/team/delegated';
export const OVERDUE_TASKS = '/tasks/team/overdue';
export const TEAM_WEEKLY_TASKS = '/tasks/team/today/list';
export const TEAM_WEEKLY_BOARD_TASKS = '/tasks/team/today/board';
export const TEAM_STATUS_TASKS = '/tasks/team/status/list';
export const TEAM_UPCOMING_TASKS = '/tasks/team/upcoming';
export const TEAM_TASK_ACTIVITY = '/tasks/team/activity';

export const MY_ACTIVE_KEY = ['/tasks/me'];
export const TEAM_ACTIVE_KEY = ['/tasks/team'];

export const MY_TODAY_BOARD_PARAM = 'me/today/board';
export const TEAM_TODAY_BOARD_PARAM = 'team/today/board';

export const MY_STATUS_BOARD_PARAM = 'me/status/board';
export const TEAM_STATUS_BOARD_PARAM = 'team/status/board';
