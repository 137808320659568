import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Pagination = ({
  currentPage,
  totalItems,
  perPage,
  onPageChange,
  className = '',
  showPageInfo = true,
}) => {
  const totalPages = Math.ceil(totalItems / perPage);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const showPages = 5;

    pageNumbers.push(1);

    let start = Math.max(2, currentPage - Math.floor(showPages / 2));
    let end = Math.min(totalPages - 1, start + showPages - 1);

    if (end === totalPages - 1) {
      start = Math.max(2, end - showPages + 1);
    }

    if (start > 2) {
      pageNumbers.push('...');
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    if (end < totalPages - 1) {
      pageNumbers.push('...');
    }

    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  if (totalPages <= 1) return null;

  return (
    <div className={`pagination-container ${className}`}>
      <button
        className='pagination-button'
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}>
        Previous
      </button>

      <div className='page-numbers'>
        {getPageNumbers().map((pageNum, index) =>
          pageNum === '...' ? (
            <span key={`ellipsis-${index}`} className='ellipsis'>
              ...
            </span>
          ) : (
            <button
              key={pageNum}
              className={`page-number ${
                pageNum === currentPage ? 'active' : ''
              }`}
              onClick={() => onPageChange(pageNum)}
              disabled={pageNum === currentPage}>
              {pageNum}
            </button>
          ),
        )}
      </div>

      {showPageInfo && (
        <span className='page-info'>
          Page {currentPage} of {totalPages}
        </span>
      )}

      <button
        className='pagination-button'
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}>
        Next
      </button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  showPageInfo: PropTypes.bool,
};

export default Pagination;
