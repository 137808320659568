import { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

export const useFetchPaginatedRecords = (query, variables = {}) => {
  const [records, setRecords] = useState([]);
  const [fetchRecords, { loading, error, data }] = useLazyQuery(query, {
    fetchPolicy: 'network-only',
    variables,
    onCompleted: (data) => {
      setRecords(data.records.results);
    },
  });

  const fetchData = useCallback(
    (params) => {
      fetchRecords({
        variables: {
          ...variables,
          ...params,
        },
      });
    },
    [fetchRecords, variables],
  );

  return { fetchData, records, total: data?.records?.total, loading, error };
};
