import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Accordion } from 'react-bootstrap';
import { loader as queryLoader } from 'graphql.macro';
import {
  isUnifiedEnabled,
  withWorkspaceIdentifier,
} from 'app/utils/Workspace/generalHelper';
import { isFeaturePermitted } from 'app/utils/userHelper';
import { has } from 'app/utils/osLodash';
import { useQuery } from '@apollo/client';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { getStickyValue } from 'app/utils/entitiesHelper';
import SpaceList from 'app/components/ContentWithSidebars/SpaceList';
import { connect } from 'react-redux';
import DirectMessengersList from 'app/components/ContentWithSidebars/DirectMessageList';
import TeamAccordion from 'app/components/ContentWithSidebars/TeamAccordion';
import PatientDirectoryList from 'app/components/ContentWithSidebars/PatientDirectoryList';
import EasyRXPrescriptionStatusList from 'app/components/ContentWithSidebars/EasyRXPrescriptionStatusList';
import SideBarFolderList from 'app/components/SideBarFolderList';
import NewFolderButton from 'app/components/FoldersView/NewFolderButton';
import NewGroupButton from 'app/components/GroupMessenger/NewGroupButton';
import PartnerSpacesAccordian from 'app/components/Partners/PartnerSpacesAccordian';
import {
  ASSIGNED_BY_ME_TASKS,
  MY_ACTIVE_KEY,
  MY_INBOX_TASKS,
  MY_OVERDUE_TASKS,
  MY_STATUS_BOARD_PARAM,
  MY_STATUS_TASKS,
  MY_TASK_ACTIVITY,
  MY_TODAY_BOARD_PARAM,
  MY_UPCOMING_TASKS,
  MY_WEEKLY_BOARD_TASKS,
  MY_WEEKLY_TASKS,
  TEAM_ACTIVE_KEY,
  TEAM_STATUS_BOARD_PARAM,
  TEAM_STATUS_TASKS,
  TEAM_TASK_ACTIVITY,
  TEAM_TODAY_BOARD_PARAM,
  TEAM_UPCOMING_TASKS,
  TEAM_WEEKLY_BOARD_TASKS,
  TEAM_WEEKLY_TASKS,
  OVERDUE_TASKS,
} from 'app/routes/constants/taskManager';

const LEFT_SIDEBAR_META_INFO_QUERY = queryLoader(
  'app/graphql/Workspace/Queries/LeftSidebarMetaInfo.gql',
);

const PARTNERS_ACCORDION_MAPPER = [
  {
    id: '0',
    activeKeyValue: '1001',
    header: 'Invite Partner Clinic',
    headerAction: false,
    icon: 'members',
    key: 'TeamSpace',
    name: 'invite_partner_clinic',
    headerOnClick: true,
  },
  // {
  //   id: '1',
  //   activeKeyValue: '1002',
  //   header: 'Care AI',
  //   headerAction: false,
  //   icon: 'message',
  //   key: 'TeamSpace',
  //   name: 'care ai',
  // },
];

const TEAMSPACE_ACCORDION_MAPPER = [
  {
    id: '0',
    header: 'Groups',
    headerAction: true,
    icon: 'members',
    key: 'TeamSpace',
    name: 'group',
    component: 'TeamSpaceList',
    SubAccordion: true,
  },
  {
    id: '1',
    header: 'Direct Messages',
    headerAction: false,
    icon: 'message',
    key: 'Messenger',
    name: 'dm',
    component: 'MessengerList',
    SubAccordion: true,
  },
];

const PATIENT_ACCORDION_MAPPER = [
  {
    id: '0',
    header: 'Patient Directory',
    headerAction: false,
    icon: 'case-person',
    key: 'PatientDirectory',
    activeKeys: ['/patient/directory'],
    component: 'PatientDirectoryList',
    SubAccordion: true,
    titleLink: '/patient/directory/all',
  },
  {
    id: '1',
    header: 'Patient Activity',
    headerAction: false,
    icon: 'case-heart',
    key: 'CareSpace',
    activeKeys: ['/spaces'],
    component: 'CareSpacesList',
    SubAccordion: true,
    titleLink: '/patient/activity',
  },
];

const TASKS_ACCORDION_MAPPER = [
  {
    id: '0',
    header: 'My Tasks',
    headerAction: false,
    icon: 'case-person',
    key: 'MyTasks',
    activeKeys: MY_ACTIVE_KEY,
    list: [
      {
        label: 'Inbox',
        url: MY_INBOX_TASKS,
        countRequired: true,
      },
      {
        label: 'Weekly',
        url: MY_WEEKLY_TASKS,
        countRequired: false,
      },
      {
        label: 'Status',
        url: MY_STATUS_TASKS,
        countRequired: false,
      },
      {
        label: 'Overdue',
        url: MY_OVERDUE_TASKS,
        countRequired: false,
      },
      {
        label: 'Upcoming',
        url: MY_UPCOMING_TASKS,
        countRequired: false,
      },
    ],
    component: 'MyTasksList',
    SubAccordion: true,
    titleLink: MY_TASK_ACTIVITY,
  },
  {
    id: '1',
    header: 'Team Tasks',
    headerAction: false,
    icon: 'case-heart',
    key: 'TeamTasks',
    activeKeys: TEAM_ACTIVE_KEY,
    list: [
      {
        label: 'Assigned By Me',
        url: ASSIGNED_BY_ME_TASKS,
        countRequired: false,
      },
      {
        label: 'Weekly',
        url: TEAM_WEEKLY_TASKS,
        countRequired: false,
      },
      {
        label: 'Status',
        url: TEAM_STATUS_TASKS,
        countRequired: false,
      },
      {
        label: 'Upcoming',
        url: TEAM_UPCOMING_TASKS,
        countRequired: false,
      },
    ],
    component: 'TeamTasksList',
    SubAccordion: true,
    titleLink: TEAM_TASK_ACTIVITY,
  },
];
const datePatternForMe = /^me\/\d{8}\/(list|board)$/;
const datePatternForTeam = /^team\/\d{8}\/(list|board)$/;

const UNIFIED_ACCORDION_MAPPER = [
  {
    id: '0',
    header: 'MY INBOX',
    headerAction: false,
    icon: 'case-person',
    key: 'MyInbox',
    activeKeys: [],
    list: [
      {
        label: 'Tasks',
        key: 'inboxTask',
        url: '/inbox/my/tasks',
        countRequired: true,
      },
      {
        label: 'Patients',
        key: 'inboxPatient',
        url: '/inbox/my/patients',
        countRequired: true,
      },
      {
        label: 'Groups',
        key: 'inboxGroups',
        url: '/inbox/my/groups',
        countRequired: true,
      },
      {
        label: 'DMs',
        key: 'inboxConversation',
        url: '/inbox/my/conversations',
        countRequired: true,
      },
      {
        label: 'Partners',
        key: 'inboxPartner',
        url: '/inbox/my/partners',
        countRequired: true,
        featureFlag: 'workspace_partners_enabled',
      },
    ],
    component: 'MyInboxList',
    SubAccordion: true,
    titleLink: '/inbox/my/tasks',
  },
  {
    id: '1',
    header: 'SHARED OFFICE INBOX',
    headerAction: false,
    icon: 'clock',
    key: 'TeamInbox',
    activeKeys: [],
    list: [
      {
        label: 'Patients',
        key: 'teamInboxPatient',
        url: '/inbox/team/patients',
        countRequired: true,
        newRequiredKey: 'new_care_activities_present',
      },
      {
        label: 'Partners',
        key: 'teamInboxPartner',
        url: '/inbox/team/partners',
        countRequired: true,
        featureFlag: 'workspace_partners_enabled',
        newRequiredKey: 'new_partner_activities_present',
      },
    ],
    component: 'TeamInboxList',
    SubAccordion: true,
    titleLink: '/inbox/team/patients',
  },
  {
    id: 'dashboard',
    header: 'Dashboard',
    headerAction: false,
    isSeperator: true,
    isInitial: true,
    activeKeys: [],
  },
  {
    id: '2',
    header: 'MY TASKS',
    headerAction: false,
    icon: 'case-person',
    isInitial: true,
    key: 'MyTasks',
    activeKeys: MY_ACTIVE_KEY,
    list: [
      {
        label: 'Weekly',
        url: MY_WEEKLY_TASKS,
        countRequired: false,
      },
      {
        label: 'Status',
        url: MY_STATUS_TASKS,
        countRequired: false,
      },
      {
        label: 'Overdue',
        url: MY_OVERDUE_TASKS,
        countRequired: false,
      },
      {
        label: 'Upcoming',
        url: MY_UPCOMING_TASKS,
        countRequired: false,
      },
    ],
    component: 'MyTasksList',
    SubAccordion: true,
    titleLink: MY_WEEKLY_TASKS,
  },
  {
    id: '3',
    header: 'TEAM TASKS',
    headerAction: false,
    icon: 'task',
    key: 'TeamTasks',
    activeKeys: TEAM_ACTIVE_KEY,
    list: [
      {
        label: 'Weekly',
        url: TEAM_WEEKLY_TASKS,
        countRequired: false,
      },
      {
        label: 'Assigned By Me',
        url: ASSIGNED_BY_ME_TASKS,
        countRequired: false,
      },
      {
        label: 'Overdue',
        url: OVERDUE_TASKS,
        countRequired: false,
      },
      {
        label: 'Status',
        url: TEAM_STATUS_TASKS,
        countRequired: false,
      },
      {
        label: 'Upcoming',
        url: TEAM_UPCOMING_TASKS,
        countRequired: false,
      },
    ],
    component: 'TeamTasksList',
    SubAccordion: true,
    titleLink: TEAM_WEEKLY_TASKS,
  },
  {
    id: '5',
    header: 'DIRECT MESSAGES',
    headerAction: false,
    icon: 'messages',
    key: 'Messenger',
    activeKeys: ['/team/dm'],
    name: 'dm',
    component: 'MessengerList',
    SubAccordion: true,
    titleLink: '/team/dm/recent',
  },
  {
    id: '4',
    header: 'GROUPS',
    headerAction: true,
    icon: 'members',
    key: 'TeamSpace',
    activeKeys: ['/team/group'],
    name: 'group',
    component: 'TeamSpaceList',
    SubAccordion: true,
    titleLink: '/team/group/recent',
  },
  {
    id: '6',
    header: 'FOLDERS',
    headerAction: true,
    icon: 'folders',
    key: 'Folders',
    activeKeys: ['/folders'],
    component: 'FoldersList',
    SubAccordion: true,
    titleLink: '/folders/my/files',
  },
  {
    id: '7',
    header: 'PATIENTS',
    headerAction: false,
    icon: 'case-person',
    key: 'PatientDirectory',
    activeKeys: ['/patient/directory'],
    component: 'PatientDirectoryList',
    SubAccordion: true,
    titleLink: '/patient/directory/all',
  },
  {
    id: '8',
    header: 'ACTIVITY',
    headerAction: false,
    icon: 'case-heart',
    key: 'Activity',
    activeKeys: ['/explore', '/patient/activity', '/tasks/team/activity'],
    list: [
      // {
      //   label: 'All',
      //   url: '/patient/activity',
      //   countRequired: true,
      // },
      {
        label: 'Patients',
        url: '/patient/activity',
        countRequired: true,
      },
      {
        label: 'Team Tasks',
        url: '/tasks/team/activity',
        countRequired: true,
      },
    ],
    component: 'ActivityList',
    SubAccordion: true,
    titleLink: '/explore',
  },
  {
    id: '9',
    header: 'EASY RX',
    headerAction: false,
    icon: 'learning_hub',
    key: 'EasyRX',
    activeKeys: ['/easy-rx/prescriptions', '/easy-rx/setup'],
    component: 'EasyRxSection',
    titleLink: '/easy-rx/prescriptions',
    SubAccordion: true,
  },
];

class LeftSidebar extends Component {
  state = {
    accordionActiveKeys: [],
  };
  getSourcePageClass() {
    return this.props.source || '';
  }

  componentDidMount() {
    const sourcePage = this.activeAccordionPage();
    const defaultActiveKeys = isUnifiedEnabled()
      ? this.getUnifiedPageActiveAcordionIds(sourcePage)
      : this.getPageActiveAccordionIds(sourcePage);
    this.setState({
      accordionActiveKeys: defaultActiveKeys,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.key !== prevProps.location.key
      // this.props.allSidebarCareNotifications.isNotificationAvailable
    ) {
      this.props.refetch();
    }
  }

  getSourcePage = (source) => {
    switch (source) {
      case 'unifiedFolders':
        return 'unifiedFolders';
      case 'unifiedInbox':
      case 'unifiedMyTasksInbox':
      case 'unifiedTeamInbox':
      case 'unifiedTeamPartnersInbox':
      case 'unifiedInboxPartner':
        return 'unifiedInbox';
      case 'teamWindow':
      case 'teamDirectMessenger':
        return 'dm';
      case 'teamGroupMessenger':
        return 'group';
      case 'exploreCareWorkspace':
      case 'userDirectory':
      case 'careSpaceDetail':
        return 'patient';
      case 'partnersMobile':
      case 'partners':
      case 'noPartners':
        return 'partners';
      case 'myTasksManager':
        return 'myTasksManager';
      case 'teamTasksManager':
        return 'teamTasksManager';
      case 'tasksManager':
      case 'tasks':
        return 'tasksManager';
      case 'boardTasksManager':
        return 'boardTasksManager';
      case 'easyRX':
        return source;
      default:
        return '';
    }
  };

  getPageAccordion = (source) => {
    switch (source) {
      case 'dm':
      case 'group':
        return isUnifiedEnabled()
          ? UNIFIED_ACCORDION_MAPPER
          : TEAMSPACE_ACCORDION_MAPPER;
      case 'unifiedFolders':
      case 'unifiedInbox':
      case 'unifiedMyTasksInbox':
      case 'unifiedTeamInbox':
      case 'unifiedTeamPartnersInbox':
      case 'unifiedInboxPartner':
      case 'easyRX':
        return UNIFIED_ACCORDION_MAPPER;
      case 'patient':
        return isUnifiedEnabled()
          ? UNIFIED_ACCORDION_MAPPER
          : PATIENT_ACCORDION_MAPPER;
      case 'partners':
        return PARTNERS_ACCORDION_MAPPER;
      case 'myTasksManager':
      case 'teamTasksManager':
      case 'tasksManager':
      case 'boardTasksManager':
        return isUnifiedEnabled()
          ? UNIFIED_ACCORDION_MAPPER
          : TASKS_ACCORDION_MAPPER;
      default:
        return [];
    }
  };

  isActivePath = (link) => {
    return this.props.location.pathname.startsWith(
      withWorkspaceIdentifier(link),
    );
  };

  getAccordionCollapsedByUrl = (filterIds) => {
    const ACCORDION_ITEMS = this.getPageAccordion(this.activeAccordionPage());
    const FILTERED_ACCORDIONS = ACCORDION_ITEMS.filter((item) =>
      filterIds.includes(item.id),
    );

    for (const item of FILTERED_ACCORDIONS) {
      const isActive = item.activeKeys.find(this.isActivePath);
      if (isActive) return [item.id.toString()];
    }
    return filterIds;
  };

  unfiedOpenAccordions = (values) => {
    return ['0', '1', ...values];
  };

  getUnifiedPageActiveAcordionIds = (source) => {
    switch (source) {
      case 'dm':
        return this.unfiedOpenAccordions(['5']);
      case 'group':
        return this.unfiedOpenAccordions(['4']);
      case 'myTasksManager':
      case 'teamTasksManager':
      case 'tasksManager':
      case 'boardTasksManager':
        return this.unfiedOpenAccordions(
          this.getAccordionCollapsedByUrl(['2', '3']),
        );
      case 'patient':
        return this.unfiedOpenAccordions(
          this.getAccordionCollapsedByUrl(['7', '8']),
        );
      case 'partners':
        return ['0', '1', '2'];
      case 'unifiedFolders':
        return this.unfiedOpenAccordions(['6']);
      case 'unifiedInbox':
      case 'unifiedMyTasksInbox':
      case 'unifiedTeamInbox':
      case 'unifiedTeamPartnersInbox':
      case 'unifiedInboxPartner':
        return this.getAccordionCollapsedByUrl(['0', '1']);
      default:
        return [];
    }
  };

  getPageActiveAccordionIds = (source) => {
    switch (source) {
      case 'dm':
      case 'group':
      case 'myTasksManager':
      case 'teamTasksManager':
      case 'tasksManager':
      case 'boardTasksManager':
        return ['0', '1'];
      case 'patient':
        return ['0', '1'];
      case 'partners':
        return ['0', '1', '2'];
      default:
        return [];
    }
  };

  redirectToPage = ({ titleLink, actionRequired, key, id }, e) => {
    if (actionRequired) {
      switch (key) {
        case 'TeamSpace':
          //   this.props.openPartnerSpaceInviteModal();
          break;
        default:
          break;
      }
    } else if (titleLink) {
      e.preventDefault();
      this.props.navigate(titleLink);
    }
    if (isUnifiedEnabled()) {
      const { accordionActiveKeys } = this.state;
      if (id !== '0' && id !== '1') {
        if (accordionActiveKeys.includes(id)) {
          this.setState((prev) => ({
            ...prev,
            accordionActiveKeys: accordionActiveKeys.filter(
              (key) => key !== id,
            ),
          }));
        } else {
          this.setState((prev) => ({
            ...prev,
            accordionActiveKeys: [...accordionActiveKeys, id],
          }));
        }
      }
    }
  };

  renderPatientDirectoryList(item) {
    // extract the pathname after removing the last word
    const pathArray = item.titleLink.split('/');
    pathArray.pop();
    const updatedPath = pathArray.join('/');
    const isActive = this.props.location.pathname.startsWith(
      withWorkspaceIdentifier(updatedPath),
    );
    const filter = this.props.location.pathname.split('/').pop();
    return (
      <PatientDirectoryList
        // redirectToPage={this.redirectToPage}
        item={item}
        isActive={isActive}
        filter={filter}
        workspace={this.props.data?.workspace || this.props.workspace?.data}
      />
    );
  }

  renderEasyRxSection(item) {
    return <EasyRXPrescriptionStatusList />;
  }

  renderCareSpacesList(item) {
    return <SpaceList type='AllSidebarCareSpaces' item={item} />;
  }

  renderCareSpaceCount() {
    return this.props.workspace?.data?.unread_care_notifications_count || '';
  }

  renderEasyRXCount() {
    return '';
  }

  renderMyInboxCount() {
    return '';
  }
  renderTeamInboxCount() {
    return '';
  }
  renderActivityCount() {
    return '';
  }

  widgetSourceObject() {
    return this.props.options && this.props.options.widgetSourceObject;
  }

  renderPatientDirectoryCount() {
    return '';
  }

  renderMyTasksCount() {
    return '';
    // return this.props.workspace?.data?.unread_task_mentions_count || '';
  }

  renderTeamTasksCount() {
    return '';
  }

  renderMessengerList() {
    return <DirectMessengersList source={this.props.source} />;
  }

  renderMessengerCount() {
    return '';
  }

  renderTeamSpaceList() {
    return <SpaceList type='AllSidebarTeamSpaces' />;
  }

  renderTeamSpaceCount() {
    return (
      this.props.workspace?.data?.unread_team_spaces_notifications_count || ''
    );
  }

  renderTeamSpaceAction() {
    return <NewGroupButton />;
  }

  renderFoldersList(item) {
    return <SideBarFolderList />;
  }

  renderFoldersCount() {
    return (
      this.props.workspace?.data?.unread_folder_spaces_notifications_count || ''
    );
  }

  renderFoldersAction() {
    return <NewFolderButton type={'icon'} />;
  }

  isAccordionActive = (item) => {
    if (item.activeKeys) {
      const isActive = item.activeKeys.find(this.isActivePath);
      if (isActive) return true;
    }

    return this.props.location.pathname.startsWith(
      withWorkspaceIdentifier(item?.titleLink),
    );
  };

  accordionHeader(item) {
    let className = 'ac-header';
    className +=
      this.isAccordionActive(item) && !isUnifiedEnabled() ? ' active' : '';
    className += item.isSeperator ? ' ac-seperator' : '';
    className += item.isInitial ? ' ac-first' : '';
    className += item.activeKeys.length > 0 ? ' actionable-item' : '';
    return (
      <Accordion.Header
        className={className}
        onClick={this.redirectToPage.bind(this, item)}>
        <OrthoIcon name={item.icon} defaultClass='me-1 item-icon' />
        {item.activeKeys.length > 0 && this.renderChevronIcon(item.id)}
        <span className='ac-heading'>{item.header}</span>
        {item.key && (
          <div className='ac-right-items'>
            <span className='lt-count'>{this[`render${item.key}Count`]()}</span>
            {item.headerAction && (
              <div
                className='btnContainer'
                onClick={(e) => e.stopPropagation()}>
                {this[`render${item.key}Action`]()}
              </div>
            )}
          </div>
        )}
      </Accordion.Header>
    );
  }

  userAccordionHeader() {
    return (
      <Accordion.Header className='ac-nst-header'>
        <span className='ac-heading'>Today</span>
        <OrthoIcon name='chevron-down' defaultClass='ml-auto' />
      </Accordion.Header>
    );
  }

  renderCarespaceUserList() {
    return (
      <Accordion
        defaultActiveKey='0'
        alwaysOpen={true}
        className='left-ac-nested'>
        <Accordion.Item eventKey='0'>
          {this.userAccordionHeader()}
          <Accordion.Body>neha</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }

  isActive = (url) => {
    let params = this.props.match.params['*'];
    if (params === '') return false;
    if (params === MY_TODAY_BOARD_PARAM && url === MY_WEEKLY_TASKS) return true;
    if (params === TEAM_TODAY_BOARD_PARAM && url === TEAM_WEEKLY_TASKS)
      return true;

    if (params === MY_STATUS_BOARD_PARAM && url === MY_STATUS_TASKS)
      return true;
    if (params === TEAM_STATUS_BOARD_PARAM && url === TEAM_STATUS_TASKS)
      return true;

    if (
      datePatternForMe.test(params) &&
      (url === MY_WEEKLY_TASKS || url === MY_WEEKLY_BOARD_TASKS)
    )
      return true;

    if (
      datePatternForTeam.test(params) &&
      (url === TEAM_WEEKLY_TASKS || url === TEAM_WEEKLY_BOARD_TASKS)
    )
      return true;

    return url.includes(params);
  };

  redirectTo = (url) => {
    this.props.navigate(withWorkspaceIdentifier(url));
  };

  getCount = (tab) => {
    if (tab === 'Inbox') {
      return this.props?.data?.workspace?.unread_task_notifications_count || '';
    }
    if (isUnifiedEnabled()) {
      if (tab === 'inboxTask') {
        return this.props.workspace?.data?.unread_task_inbox_count || '';
      } else if (tab === 'inboxPatient') {
        return this.props.workspace?.data?.unread_patient_inbox_count || '';
      } else if (tab === 'inboxGroups') {
        return this.props.workspace?.data?.unread_group_inbox_count || '';
      } else if (tab === 'inboxConversation') {
        return (
          this.props.workspace?.data?.unread_conversation_inbox_count || ''
        );
      } else if (tab === 'inboxPartner') {
        return this.props.workspace?.data?.unread_partner_inbox_count || '';
      } else if (tab === 'teamInboxPatient') {
        return (
          this.props.workspace?.data
            ?.unprocessed_patient_actionable_items_count || ''
        );
      } else if (tab === 'teamInboxPartner') {
        return (
          this.props.workspace?.data
            ?.unprocessed_partner_actionable_items_count || ''
        );
      }
    }
  };

  renderMyTasksList(item) {
    return this.renderTasksList(item);
  }

  renderTeamTasksList(item) {
    return this.renderTasksList(item);
  }

  renderMyInboxList(item) {
    return this.renderTasksList(item);
  }
  renderTeamInboxList(item) {
    return this.renderTasksList(item);
  }

  renderActivityList(item) {
    return this.renderTasksList(item);
  }

  renderNotificationCount(item) {
    const notificationKey = item.key ? item?.key : item.label;
    const itemNotification = this.getCount(notificationKey);
    if (itemNotification > 0)
      return <span className='lt-count'>{itemNotification}</span>;
  }

  renderNewKey(item) {
    const value =
      item.newRequiredKey &&
      this.props.data?.workspace &&
      this.props.data?.workspace[item.newRequiredKey]
        ? 'NEW'
        : '';
    return <span className='new me-1'>{value}</span>;
  }

  renderTasksList(items) {
    return (
      <div className='tasks-list'>
        {items.list
          .filter(
            (item) =>
              !has(item, 'featureFlag') || isFeaturePermitted(item.featureFlag),
          )
          .map((item) => {
            const isActive = isUnifiedEnabled()
              ? this.isActivePath(item.url)
              : this.isActive(item.url);

            let itemClass = 'tasks-list-item ';
            itemClass += item.url.includes('inbox') ? 'u-inbox ' : '';
            return (
              <div
                style={{
                  backgroundColor: isActive ? '#eff1f4' : null,
                }}
                className={itemClass}
                onClick={() => this.redirectTo(item.url)}>
                <span className='item-text' key={item.label}>
                  {item.label}
                </span>
                {this.renderNewKey(item)}
                {item.countRequired && this.renderNotificationCount(item)}
              </div>
            );
          })}
      </div>
    );
  }

  getAccordionList = (item) => {
    const loadAccordionBody = isUnifiedEnabled()
      ? this.state.accordionActiveKeys.includes(item.id)
      : true;
    return (
      <Accordion.Item eventKey={item.id} key={item.id}>
        {this.accordionHeader(item)}
        {loadAccordionBody && item.component && (
          <Accordion.Body>
            {this[`render${item.component}`](item)}
          </Accordion.Body>
        )}
      </Accordion.Item>
    );
  };

  openAccordion(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      accordionActiveKeys: [id, ...this.state.accordionActiveKeys],
    });
  }

  closeAccordion(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      accordionActiveKeys: this.state.accordionActiveKeys.filter(
        (i) => i !== id,
      ),
    });
  }

  renderChevronIcon(id) {
    if (this.state.accordionActiveKeys.includes(id)) {
      return (
        <OrthoIcon
          defaultClass='lt-bar-icon me-1'
          name={'chevron-caret-up'}
          onClick={(e) => this.closeAccordion(e, id)}
        />
      );
    } else {
      return (
        <OrthoIcon
          defaultClass='lt-bar-icon me-1'
          name={'chevron-caret-down'}
          onClick={(e) => this.openAccordion(e, id)}
        />
      );
    }
  }

  teamAccordionHeader(item, activeKeys) {
    let className = 'ac-header';
    const sourcePage = this.activeAccordionPage();
    // if (activeKeys && activeKeys.includes(item.id)) {
    //   // Make accordion active based on currently opened Accordion
    //   className += ' active';
    // }

    // Make Accordion active according to current selection
    if (item.name === sourcePage) {
      className += ' active';
    }
    return (
      <Accordion.Header
        className={className}
        onClick={this.redirectToPage.bind(this, item)}>
        <OrthoIcon name={item.icon} defaultClass='me-1' />
        <span className='ac-heading'>{item.header}</span>
        <span className='lt-count'>{this[`render${item.key}Count`]()}</span>
        {item.headerAction && this[`render${item.key}Action`]()}
      </Accordion.Header>
    );
  }

  getTeamAccordionList = (item, activeKeys) => {
    return (
      <Accordion.Item eventKey={item.id} data-key={item.id}>
        {this.teamAccordionHeader(item, activeKeys)}
        {item.component && (
          <div class='ac-body' id={`${item.name}-accordion`}>
            <Accordion.Body>{this[`render${item.component}`]()}</Accordion.Body>
          </div>
        )}
      </Accordion.Item>
    );
  };

  activeAccordionPage = () => {
    return this.getSourcePage(this.getSourcePageClass());
  };

  render() {
    let className = 'leftbar sidebar_fixed ';
    className += this.getSourcePageClass();
    let accordionClass = 'left-ac ';
    accordionClass += this.props.device.mobileDevice ? 'left-ac-mobile' : '';

    const sourcePage = this.activeAccordionPage();
    const accordionItems = this.getPageAccordion(sourcePage);
    const defaultActiveKeys = isUnifiedEnabled()
      ? this.getUnifiedPageActiveAcordionIds(sourcePage)
      : this.getPageActiveAccordionIds(sourcePage);

    if (!isUnifiedEnabled() && ['group', 'dm'].includes(sourcePage)) {
      return (
        <TeamAccordion
          className={className}
          list={accordionItems}
          defaultActiveKey={defaultActiveKeys}
          style={{
            top: getStickyValue(this.props.source).stickyTop,
            overflowY: 'hidden',
          }}>
          {(activeKeys) =>
            accordionItems?.map((item) =>
              this.getTeamAccordionList(item, activeKeys),
            )
          }
        </TeamAccordion>
      );
    } else if (sourcePage === 'partners') {
      return (
        <PartnerSpacesAccordian
          accordionItems={accordionItems}
          defaultActiveKeys={defaultActiveKeys}
          className={className}
          accordionClass={accordionClass}
          stickyTop={getStickyValue(this.props.source).stickyTop}
        />
      );
    }

    return (
      <article
        id={'left_side_bar'}
        className={className}
        style={{
          top: getStickyValue(this.props.source).stickyTop,
        }}>
        <Accordion
          alwaysOpen={true}
          defaultActiveKey={defaultActiveKeys}
          activeKey={this.state.accordionActiveKeys}
          className={accordionClass}>
          {accordionItems.map(this.getAccordionList)}
        </Accordion>
      </article>
    );
  }
}

const ConnectedLeftSidebar = (props) => {
  const { data, refetch } = useQuery(LEFT_SIDEBAR_META_INFO_QUERY, {
    variables: {
      workspaceIdentifier: props.workspace.identifer,
    },
    fetchPolicy: 'network-only',
  });
  return <LeftSidebar {...props} data={data} refetch={refetch} />;
};

export default connect(
  ({ allSidebarCareNotifications, workspace, device }) => ({
    allSidebarCareNotifications,
    workspace,
    device,
  }),
)(withRouter(withApollo(ConnectedLeftSidebar)));
