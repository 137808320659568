import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { flashError } from 'app/actions/flashMessage';
import { upperFirst } from 'app/utils/osLodash';
import { useDispatch } from 'react-redux';
const ADD_NEW_CONTRACT = queryLoader(
  'app/graphql/mutations/Contracts/AddNewContract.gql',
);

const ERROR_MESSAGE =
  'Error adding contract, Please try again or Contact Support';

export const useAddNewContract = () => {
  const dispatch = useDispatch();
  const [contract, setContract] = useState(null);
  const [addNewContract, { loading }] = useMutation(ADD_NEW_CONTRACT);

  const addContract = useCallback(
    (variables) => {
      return addNewContract({
        variables,
      }).then((res) => {
        if (res.data.addNewContract.entity) {
          setContract(res.data.addNewContract.entity);
          return { success: true };
        } else {
          const error = res.data.addNewContract.errors?.[0];
          const errorMessage = error
            ? `${upperFirst(error.field)} ${error.message}`
            : ERROR_MESSAGE;
          dispatch(flashError(errorMessage, false));
          return { success: false };
        }
      });
    },
    [addNewContract, dispatch, setContract],
  );

  return {
    addContract,
    loading,
    contract,
  };
};
