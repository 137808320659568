import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import OsBtn from 'app/components/OsBtn';
import OsCards from 'app/components/OsCards';
import OsGrid from 'app/components/OsGrid';
import { dateWithFormat } from 'app/utils/timeHelper';

import { useDispatch, useSelector } from 'react-redux';
import {
  getCommentShareFiles,
  getCommentShareIsClosed,
} from 'app/selectors/commentShare';
import {
  commentShareConsume,
  commentShareOpen,
} from 'app/actions/commentShare';
import { mapLocalFileWithAttachmentObject } from 'app/utils/entitiesHelper';
import { isBlob } from 'app/utils/fileHelper';
import { useAddNewContract } from 'app/hooks/careSpaces/useAddNewContract';

import './style.scss';
const ContractModal = ({ onClose, onSubmit, spaceId }) => {
  const dispatch = useDispatch();
  const [contractName, setContractName] = useState('');
  const [contractNumber, setContractNumber] = useState('');
  const [contractDate, setContractDate] = useState(new Date());
  const attachments = useSelector(getCommentShareFiles);
  const commentShareClosed = useSelector(getCommentShareIsClosed);
  const [assigningObject, setAssigningObject] = useState(false);
  const { addContract, loading } = useAddNewContract();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (commentShareClosed && assigningObject) {
      setFiles(attachments);
      setAssigningObject(false);
      // dispatch(commentShareConsume());
    }
  }, [commentShareClosed, dispatch, setFiles, attachments, assigningObject]);

  const renderList = (objects, afterClose) => {
    return objects.map((object) => renderCard(object, afterClose));
  };

  const renderCard = (object, afterClose) => {
    return (
      <OsGrid
        identifier={'ContractCard:Cardcols'}
        key={`${object.__typename}:${object.id}`}>
        <OsCards
          size={'extra-small'}
          disableCard={false}
          selectionMode={false}
          obj={object}
          closeRequired={true}
          afterClose={afterClose}
          avoidPreviewModal={true}
        />
      </OsGrid>
    );
  };

  const removeAttachment = () => {
    setFiles([]);
  };

  const renderAttachments = () => {
    if (files.length > 0) {
      return (
        <div className='row comment-attachment-block row-col-space-16'>
          {renderList(
            files.map((file) =>
              isBlob(file) ? mapLocalFileWithAttachmentObject(file) : file,
            ),
            removeAttachment,
          )}
        </div>
      );
    }
  };

  const assignObject = (type = 'file') => {
    setAssigningObject(true);
    dispatch(
      commentShareOpen(type, 'Comment', [], [], {
        maxFilesToUpload: 1,
        typesToBeDisplayed: ['file'],
      }),
    );
  };

  const isFormValid = () => {
    return contractName && contractNumber && contractDate && files.length > 0;
  };

  const handleSubmit = () => {
    const variables = {
      careSpaceId: String(spaceId),
      name: contractName,
      date: dateWithFormat(contractDate, 'YYYY-MM-DD'),
      number: contractNumber,
      attachmentId: String(files[0].id),
    };
    addContract(variables).then(({ success }) => {
      if (success) {
        onClose();
      }
    });
  };

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create New Contract</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className='row'>
          <Form.Group className='mb-3'>
            <Form.Label>Contract Name*</Form.Label>
            <Form.Control
              type='text'
              value={contractName}
              onChange={(e) => setContractName(e.target.value)}
              placeholder='Enter contract name'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Contract Number*</Form.Label>
            <Form.Control
              type='text'
              value={contractNumber}
              onChange={(e) => setContractNumber(e.target.value)}
              placeholder='Enter contract Number'
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Contract Date*</Form.Label>
            <Form.Control
              type='date'
              value={contractDate.toISOString().split('T')[0]}
              onChange={(e) => setContractDate(new Date(e.target.value))}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <OsBtn
              icon='share-fill'
              text='Upload Contract*'
              onClick={() => assignObject('file')}
              extraClass='list-button'
              name='BtnIcon'
            />
          </Form.Group>
          {renderAttachments()}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <OsBtn
          text='Cancel'
          onClick={onClose}
          extraClass='btn-secondary'
          name='BtnSecondary'
        />
        <OsBtn
          text='Create Contract'
          onClick={handleSubmit}
          disabled={!isFormValid()}
          extraClass='btn-primary'
          name='BtnPrimary'
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ContractModal;
