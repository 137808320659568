import React from 'react';
import InvoiceList from 'app/components/InvoiceList';

const CareInvoicesListing = ({ spaceId }) => {
  return (
    <div className='invoices-page'>
      <InvoiceList spaceId={spaceId} />
    </div>
  );
};

export default CareInvoicesListing;
